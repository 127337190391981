import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import { Button } from "react-bootstrap";
import AdminUserRoles from "./AdminUser-Roles";
import AdminUserEditType from "./AdminUser-Type";
import { AiOutlineUser } from "react-icons/ai";
import { IoMdArrowBack } from "react-icons/io";
import AdminUserDelete from "./AdminUser-Delete";

export default function AdminUser({ currentUser }) {
    const params = useParams(); // parameters from URL (react-router)
    const [userData, setUserData] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/user/' + params.id, (data) => { setUserData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        //render
        console.log('userData', userData);
    }, [userData]);

    useEffect(() => {
        //setCurrentUserId(params.id);
        refresh();
    }, []);

    const goBack = (e) => {
        navigate(-1);
    }

    return (
        <>
            <h2><AiOutlineUser /> {userData.firstName} {userData.lastName}</h2>
            {!!userData.denumire && (<h4>{userData.denumire} / {userData.codFiscal}</h4>)}
            <h3>{userData.email}</h3>
            <hr />
            {/*<Tabs
                defaultActiveKey={userData.tip === 'P' ? "userType" : "userAccess"}
                id={`user-details-${userData.id}`}
                className="mb-3"
            >
             <Tab eventKey="userAccess" title="Drepturi">
             </Tab>
            */}

            {userData.tip === 'P' ?
                (
                    <AdminUserEditType user={userData} refresh={refresh} />
                )
                : (
                    <AdminUserRoles userData={userData} refresh={refresh} />
                )}
            <hr />

            <Button variant="outline-primary" onClick={goBack}><IoMdArrowBack /> Înapoi</Button>
            {currentUser.roles.includes("users.delete") && (<AdminUserDelete user={userData} />)}
        </>
    )
}