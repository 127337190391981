import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        clase: [],
        simbolClase: [],
        producatori: [],
        locationId: 0
    },
    reducers: {
        addToCart(state, action) {
            if (!!action.payload) {
                let item = action.payload;
                let existingCartItem = state.items.find(i => (i.product.id === item.product.id) && (i.boxQty === item.boxQty))
                if (existingCartItem) {
                    existingCartItem.quantity += item.quantity
                    existingCartItem.total = existingCartItem.quantity * existingCartItem.boxQty * parseFloat(existingCartItem.price)
                    console.log("Updated existing item", JSON.parse(JSON.stringify(state.items)))
                } else {
                    item.total = item.quantity * item.boxQty * parseFloat(item.price)
                    item.key = item.product.id + '-' + item.boxQty
                    state.items.push(action.payload);
                    console.log("Added to CART", JSON.parse(JSON.stringify(state.items)))
                }
            }
        },

        updateQuantity(state, action) {
            if (!!action.payload) {
                let productId = parseInt(action.payload.productId);
                let quantity = action.payload.quantity;
                let boxQty = action.payload.boxQty;
                if (quantity) {
                    let item = state.items.find(item => (item.product.id === productId) && (item.boxQty === boxQty))
                    item.quantity = quantity;
                    item.total = item.quantity * item.boxQty * parseFloat(item.product.pretSite)
                } else {
                    state.items = state.items.filter(item => !((item.product.id === productId) && (item.boxQty === boxQty)))
                }
                console.log("NewCART", JSON.parse(JSON.stringify(state.items)))
            }
        },
        
        setLocation(state, action) {
            if (!!action.payload) {
                state.locationId = parseInt(action.payload);
                console.log("Cart delivery to", state.locationId);
            }
        }
        

    }
})

export const {
    addToCart, updateQuantity, setLocation
} = cartSlice.actions
export default cartSlice.reducer