import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import { Carousel } from 'react-bootstrap';
import { useState } from 'react';

export default function PublicCarousel({ currentPageId, hasCarousel }) {
    const pages = useSelector((store) => store.content.pages);
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        // render
        console.log("Render carousel", currentPageId, pages);
    }, [pages]);

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {!!pages && pages
                .filter(p => p.carouselPages.includes(currentPageId))
                .map((p) => (
                    <Carousel.Item key={p.title}>
                        <img
                            className="d-block w-100 slide"
                            src={p.image}
                            alt={p.title}
                        />
                        {p.subtitle && (<Carousel.Caption>
                            <h3>
                                {!!p.path ?
                                    (<Nav.Link key={p.path} as={NavLink} to={`/${p.path}`}>{`${p.subtitle}`}</Nav.Link>)
                                    : (<> {p.subtitle} </>)
                                }
                            </h3>
                            <p>{p.sumar}</p>
                        </Carousel.Caption>)}
                    </Carousel.Item>
                ))}
        </Carousel>
    )
}