import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ApiPut } from "services/ApiService";
import { NavLink } from "react-router-dom";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import PasswordInputWithValidation from "./PasswordInputWithValidation";
import TimerProgress from "components/common/TimerProgress";

export default function ChangePasswordForm() {
    const params = useParams(); // params from query
    const [allOk, setAllOk] = useState(false);
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [response, setResponse] = useState(false);
    const [responseError, setResponseError] = useState(false);

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }

    useEffect(() => {
        // refresh
    }, [allOk]);
    
    const handleSubmit = (e) => {
        e.preventDefault();
        ApiPut(
            "/resetpass",
            { token: params.token, currentPassword:password, pass: newPassword },
            (data) => {
                setResponse(data);
                setResponseError(false);
            },
            (error) => {    
                setResponse(error.response.data);
                setResponseError(true);
            });
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={6} lg={4} xs={12}>
                    <Card className="shadow mt-5">
                        <Card.Body>
                            <div className="mb-3 mt-md-4">
                                <h5 className="mb-4">Schimbă parola</h5>
                                {response
                                    ? (<>
                                        <div
                                            className={responseError ? "alert alert-danger" : "alert alert-success"}
                                            role="alert">
                                            {response}
                                        </div>
                                        <div className="form-group mt-3 text-center">
                                            <NavLink to="/profile">Înapoi la pagina de profil</NavLink>
                                        </div>
                                    </>)
                                    : (
                                        <div className="mb-3">
                                            <Form onSubmit={handleSubmit}>
                                                
                                            <Form.Group className="form-group mb-3">
                                                    <Form.Label htmlFor="password" className="text-left mb-3">
                                                        Parola curentă
                                                    </Form.Label>
                                                    <Form.Control
                                                        autoComplete="off"
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        value={password}
                                                        onChange={onChangePassword}
                                                    />
                                                </Form.Group>
                                               
                                               <TimerProgress expire={params.expire} />
                                               
                                               <PasswordInputWithValidation setNewPassword={setNewPassword} setAllOk={setAllOk}/>
                                               
                                                <div className="d-grid mt-4">
                                                    <Button variant="warning" type="submit" disabled={!allOk}>
                                                        Schimbă parola
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}