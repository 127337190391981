import { Form, Button } from "react-bootstrap";
import { ApiGet, ApiPost } from "services/ApiService";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError } from "store/msgStore";

export default function AdminLogs() {
    var [data, setData] = useState();
    var [log, setLog] = useState();
    
    var dispatch = useDispatch();

    useEffect(() => { }, [data, log]);

    useEffect(() => {
        ApiGet('/admin-logs', (data) => {
            setData(data)
        },
        (error) => { dispatch(setError(error)); });
    }, []);

    const showLog = (logFile) => {
        console.log("Loading log file: " + logFile);
        ApiPost('/admin-logs', {log: logFile},
        (data) => {
            setLog(data)
        },
        (error) => { dispatch(setError(error)); 
        });
    }

    return (
        <>
            <h1>Logs</h1>
                {!!data && !!data.map && data.map((l) => (
                    <Button variant="info" onClick={()=>showLog(l)}>{l}</Button>
                ))}
                
                <hr/>
                {!!log && (
                    <small><small><pre>{log}</pre></small></small>
                )}
                <hr/>
            </>
    )
}

