import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ApiDel } from "services/ApiService";
import { setTempConfirmation, setError } from "store/msgStore";

export default function AdminPageDelete({ page }) {
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const confirm = (e) => {
        console.log("confirm", e);
        setShow(true);
    }

    const handleClose = () => setShow(false);

    const pageWasDeleted = (data) => {
        dispatch(setTempConfirmation("Pagina a fost stearsa cu success."))
        navigate('/admin/pagini');
    }

    const doDelete = () => {
        ApiDel('/pages/' + page.id, (data) => { pageWasDeleted(data) }, (error) => { dispatch(setError(error)); });
        setShow(false);
    }


    return (
        <>
            <Button
                style={{ float: 'right' }}
                variant="danger"
                size="sm"
                title="Sterge"
                onClick={confirm}>
                Șterge Pagina
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">Confirmați ștergerea</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sunteți sigur că doriți să ștergeți această pagină? <br />
                    Conținutul nu va mai apărea online și nu va mai apărea online!<br /><br />
                    O altă opțiune este să <em>inactivați</em> pagina.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Anulează ștergerea
                    </Button>
                    <Button variant="danger" onClick={doDelete}>
                        ȘTERGE PAGINA
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}