import { Button, Card } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import { nl2br } from "services/Utils";
import { Form } from "react-bootstrap";
import { setLocation } from "store/cartStore";
import { FiEdit } from "react-icons/fi";


export default function CartLivrare() {
    const locationId = useSelector((storePersisted) => storePersisted.cart.locationId);

    const [data, setData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/locations', (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        refresh();
        // eslint-disable-next-line  
    }, [locationId])

    const onSelectChange = (e) => {
        if (!e || !e.target || !e.target.id) {
            return;
        }
        dispatch(setLocation(e.target.id));
    }

    const addAddress = () => {
        navigate("/profile/adauga-adresa");
    }

    return (
        <>
            <h5>Adresa de livrare</h5>
            <p className="text-muted">Alegeți o adresă de livrare din cele definite în profilul dumneavoastră, sau puteți adăuga o nouă adresă de livrare.</p>
            {
                !!data && !!data.map && data.map(i => (
                    <Card key={i.id}>
                        <Card.Header className={locationId === i.id ? "selected pt-1" : "pt-1"}>
                            <Form.Check
                                type='radio'
                                id={i.id}
                                name="location"
                                className="mt-0 pt-0"
                                label={<>
                                    <strong>{i.name}</strong>
                                    <NavLink to={`/profile/modifica-adresa/${i.id}`} className="text-primary" style={{ float: 'right' }}><FiEdit /></NavLink>
                                </>}
                                checked={locationId === i.id}
                                onChange={onSelectChange}
                            />



                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <em><small>{nl2br(i.address)}</small></em>
                                <small>{i.postalcode} - {i.city}, {i.county} </small>
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <small>Tel: {i.phone}<br />
                                Email: {i.email}</small>
                        </Card.Footer>
                    </Card>
                ))
            }
            <p>
                <Button className="text-dark ml-5" variant="outline-info" size="sm" onClick={addAddress}>Adaugă adresă</Button>
            </p>
        </>
    )
}