import { useState } from "react";
//import { useNavigate } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { login } from "services/AuthService";
import { Card, Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

//import { withRouter } from '../common/with-router';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState();
    const [checkBtn, setCheckBtn] = useState();

    const onChangeUsername = e => {
        setUsername(e.target.value);
    }

    const onChangePassword = e => {
        setPassword(e.target.value);
    }

    const required = value => {
        if (!value) {
            return (
                <div className="alert alert-danger" role="alert">
                    Vă rugăm introduceți o valoare!
                </div>
            );
        }
    };

    const handleLogin = e => {
        e.preventDefault();

        setMessage('');
        setLoading(true);

        form.validateAll();

        if (checkBtn.context._errors.length === 0) {
            console.log("Check ok, loggin in", username);

            login(username, password)
                .then(() => {
                    //TODO: try to useNavigate to make it smoother, but still reload App.js component
                    window.location.href = '/';
                },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        console.log("Error message", resMessage);

                        setLoading(false);
                        setMessage("Eroare la autentificare: " + resMessage);

                    }
                );
        } else {
            setLoading(false);
        }
    }

    return (
        <Container style={{background: 'linear-gradient(to bottom, gray, black)'}}>
            <Row className="vh-100 d-flex justify-content-center align-items-center">
                <Col md={6} lg={4} xs={12}>
                    <Card  style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/img/home1.jpg)`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                    }}>
                        <Card.Body>
                            <Card.Title className="mb-5" style={{color: 'orange', fontWeight: 'bold', fontSize: '1.5em'}}>Login parteneri B2B</Card.Title>

                            <Form
                                onSubmit={handleLogin}
                                ref={c => {
                                    setForm(c);
                                }}
                            >

                                <div className="form-group">
                                    <label htmlFor="username">Adresa email</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        name="username"
                                        value={username}
                                        onChange={onChangeUsername}
                                        validations={[required]}
                                        style={{opacity: 0.85}}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">Parola</label>
                                    <Input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        value={password}
                                        onChange={onChangePassword}
                                        validations={[required]}
                                        style={{opacity: 0.85}}
                                    />
                                </div>

                                <div className="form-group mt-3 text-center">
                                    <button
                                        className="btn btn-dark btn-block"
                                        disabled={loading}
                                    >
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        <span>Login</span>
                                    </button>
                                </div>

                                {message && (
                                    <div className="form-group mt-1">
                                        <div className="alert alert-danger" role="alert">
                                            {message}
                                        </div>
                                    </div>
                                )}

                                <div className="form-group">
                                    <CheckButton
                                        style={{ display: "none" }}
                                        ref={c => {
                                            setCheckBtn(c);
                                        }}
                                    />
                                </div>

                                <div className="form-group mt-3 text-center small">
                                    <NavLink style={{color:'black', textDecoration:'none'}} to="/recuperare-parola">Ai uitat parola?</NavLink>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;
