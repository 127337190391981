function epochToLocal(epochTime) {
    return new Intl.DateTimeFormat('ro-ro',
        {
            day: '2-digit',
            month: 'long',
            year: 'numeric',

            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',

            hour12: false,
        }).format(epochTime * 1000);
    //var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    //d.setUTCSeconds(epochTime);
    //return d.toDateString();
}

function hashCode(str) {
    var hash = 0,
        i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export { epochToLocal, hashCode };
