import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { setError, setLoading, clearError, setTempConfirmation } from "store/msgStore";
import { ApiGet, ApiPost, ApiDel } from "services/ApiService";
import { Form, Button } from "react-bootstrap";
import { Buffer } from "buffer";
import { AiFillDelete, AiFillSave } from "react-icons/ai";
import { IoMdArrowBack } from "react-icons/io";


export default function AdminMiniCarousel({ currentUser }) {
    const params = useParams(); // parameters from URL (react-router)

    const empty = {
        id: 0,
        name: "",
        title: "",
        description: "",
        path: "",
        carOrder: 0,
        image: ""
    }
    const [data, setData] = useState(empty); // need to do a deep copy
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState(null);
    const [isAdd, setAdd] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loadData = (data) => {
        setName(data.name); // default;
        if (!data.path) {
            data = { ...data, path: "" };
        }
        setData(data);
        if (!!data.description) {
            setDescription(Buffer.from(data.description, 'base64').toString());
        } else {
            setDescription("");
        }
    }

    const refresh = () => {
        dispatch(clearError());
        if (params.carId !== "+" && !!params.carId) {
            setAdd(false);
            ApiGet('/content/mini-carousel/' + params.carId, (data) => { loadData(data) }, (error) => { dispatch(setError(error)); });
        } else {
            setAdd(true);
            setData(empty);
        }
    }

    useEffect(() => {
        //render
    }, [data, name, description]);

    useEffect(() => {
        refresh();
    }, []);

    const onChangeName = (e) => {
        setData({ ...data, name: e.target.value })
    }

    const onChangeTitle = (e) => {
        setData({ ...data, title: e.target.value })
    }

    const onChangeDescription = (e) => {
        setData({ ...data, description: e.target.value })
        setDescription(e.target.value);
    }

    const onChangeCarOrder = (e) => {
        setData({ ...data, carOrder: e.target.value })
    }

    const onChangeUrl = (e) => {
        setData({ ...data, path: e.target.value })
    }

    const onChangeFile = (e) => {
        console.log("File", e.target.files);
        setFile(e.target.files[0]);
    }



    const goBack = (e) => {
        navigate(-1);
    }

    const saveMiniCarousel = (e) => {
        e.preventDefault();
        dispatch(setLoading(true));

        const formData = new FormData();

        let payload = {
            ...data,
            description: Buffer.from(description).toString("base64")
        };

        for (var key in payload) {
            formData.append(key, payload[key]);
        }
        formData.append('image', file);

        ApiPost('/content/mini-carousel/' + data.id,
            formData,
            (data) => {
                dispatch(setLoading(false));
                dispatch(setTempConfirmation("Imaginea a fost salvată"));
                goBack(e);
            },
            (error) => {
                dispatch(setLoading(false));
                dispatch(setError(error));
            });
    }

    const deleteMiniCarousel = () => {
        ApiDel('/content/mini-carousel/' + params.carId, (data) => { goBack() }, (error) => { dispatch(setError(error)); });
    }


    return (
        <Form>
            {isAdd ? (<h1>Adaugă mini-carousel</h1>) : (<h1>Modifica mini-carousel: {name}</h1>)}
            <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Nume carousel/mini-banner</Form.Label>
                <Form.Control type="text" placeholder="Nume carousel"
                    value={data.name}
                    onChange={onChangeName} />
                <Form.Text className="text-muted">
                    In ce mini-carousel va aparea aceasta imagine (se vor grupa dupa nume).<br />
                    Mini-carouselele se pot insera în conținutul principal <em>din alte pagini</em>
                    folosind secvența <strong>%</strong><em>NUME-CAROUSEL</em><strong>%</strong> sau 
                    <strong>#</strong><em>NUME-MINI-BANNER</em><strong>#</strong>
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTitle">
                <Form.Label>Titlu</Form.Label>
                <Form.Control type="text" placeholder="titlu..."
                    value={data.title}
                    onChange={onChangeTitle} />
                <Form.Text className="text-muted">
                    Titlul imaginii (apare doar la mini-banner)
                </Form.Text>
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="formTitle">
                <Form.Label>Descriere</Form.Label>
                <Form.Control type="text" placeholder="descriere ..."
                    value={description}
                    onChange={onChangeDescription} />
                <Form.Text className="text-muted">
                    Scurta descriere (apare la mini-banner)
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Imagine</Form.Label>
                <Form.Control type="file" onChange={onChangeFile} />
                <Form.Text className="text-muted">
                    {!!data.image &&
                        <>
                            Imaginea curentă:
                            <img className="mt-1" src={data.image} height="40px" alt="Imaginea curenta" />
                            <br />
                        </>}
                    Incărcați imaginea la rezoluția cea mai bună - se va scala automat.
                    Imaginile din carusel trebuie să aibă aceeași dimensiune pentru consistență.
                </Form.Text>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formMenu">
                <Form.Label>Ordine</Form.Label>
                <Form.Control type="number" placeholder="Ordinea din carousel"
                    value={data.carOrder}
                    onChange={onChangeCarOrder} />
                <Form.Text className="text-muted">
                    Ordinea din carousel
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formUrl">
                <Form.Label>URL</Form.Label>
                <Form.Control type="text" placeholder="URL"
                    value={!!data ? data.path : ""}
                    onChange={onChangeUrl} />
                <Form.Text className="text-muted">
                    Link (optional) pentru click pe imagine.
                </Form.Text>
            </Form.Group>


            <Button variant="outline-primary" className="mr-5" onClick={goBack}><IoMdArrowBack /> Înapoi</Button>{' '}
            <Button variant="success" className="ml-5" onClick={saveMiniCarousel}><AiFillSave />Salvează</Button>
            {currentUser.roles.includes("mini-carousel.admin") && (
                <Button variant="danger" style={{ float: "right" }} className="ml-auto" onClick={deleteMiniCarousel}><AiFillDelete /> Șterge</Button>
            )}
        </Form>
    )
}