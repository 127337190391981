import axios from "axios";

import * as config from '../common/config';
import { ApiPost } from "./ApiService";


function parseJwt(token) {
    return JSON.parse(atob(token.split('.')[1]));
  }

function login(username, password) {
    console.log("Logging in user:", username)
    return axios
      .post(config.API_URL + "signin", {
        username,
        password
      })
      .then(response => {
        console.log("Response", response)
        processTokens(response.data);
        return response.data;
      });
  }

  function  processTokens(data) {
    if (!!data.accessToken) {
      localStorage.setItem("user", JSON.stringify(data));
      if (!!data.refreshToken) {
        startRefreshTimer();
      } else {
        console.log("refresh token not found!");
      }
    } else {
      console.log("invalid response, access tocken not found!");
      localStorage.removeItem("user");
      window.location.href = "/";
    }
  }


  function refreshToken() {
    console.log("Refeshing token now");
    clearTimeout();
    var userData = JSON.parse(localStorage.getItem('user'));
    if (!!userData) {
      let refreshToken = userData.refreshToken;
      if (!!refreshToken) {
        ApiPost('/refresh', { refreshToken: refreshToken },
          (data) => {
            console.log("Token refreshed!")
            console.log(data);
            processTokens(data);
          },
          (error) => {
            console.log("Error while refreshing token, logging out", error);
            localStorage.removeItem("user");
            window.location.href = "/";
          });
      } else {
        console.log("Local user storage has no refresh token");
      }
    } else {
      console.log("Invalid user data in local storage");
    }

  }

  function startRefreshTimer() {
    var dateNow = new Date();
    var userData = JSON.parse(localStorage.getItem('user'));
    if (!!userData) {
      let jwt = parseJwt(userData.accessToken);
      let timeToExpire = jwt.exp * 1000 - parseInt(dateNow.getTime()) - 10 * 1000; // refresh 10 seconds before

      if (timeToExpire > 0) {
        setTimeout(refreshToken, timeToExpire);
        console.log("Refresh token timer started, will refresh in ", timeToExpire / 1000 + " sec");
      } else {
        console.log("Token expired already!");
        localStorage.removeItem("user");
        window.location.href = "/";
      }
    } else {
      console.log("No user data in local storage");
    }
  }

  function logout() {
    localStorage.removeItem("user");
  }

  function register(username, email, password) {
    return axios.post(config.API_URL + "signup", {
      username,
      email,
      password
    });
  }

  function checkExpiration(jwt) {
    var dateNow = new Date();

    return (jwt.exp < dateNow.getTime())
  }

  function getCurrentUser() {
    var userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      var jwt = parseJwt(userData.accessToken)
      if (checkExpiration(jwt)) {
        jwt.accessToken = userData.accessToken
        return jwt
      } else {
        console.log("Token expired! Forcing logout");
        logout()
        return null;
      }
    } else {
      return userData
    }
  }


export {login, logout, register, getCurrentUser, startRefreshTimer};
