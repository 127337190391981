
import { Link } from "react-router-dom";

export default function SearchLink({ query }) {
    return (
        query.split(",").map((q, index) => (
            <span key={index}>
                <Link to={`/cauta/${q.trim()}`} >{q.trim()}</Link>&nbsp;
            </span>
        ))
    )
}