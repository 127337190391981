import { useState, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setError, clearError } from "store/msgStore";
import { searchResults, setSearchQuery, setSearchType } from "store/productsReducer";
import { ApiGet } from "services/ApiService";

export default function SearchBarDirect() {
    const [queryDelayed, setDelayedQuery] = useState('');
    const [queryRaw, setQueryRaw] = useState('');
    const searchQuery = useSelector((store) => store.products.searchQuery);
    const searchType = useSelector((store) => store.products.searchType);
    const isLoading = useSelector((store) => store.msg.loading);

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const dispatchSearchResults = (data) => {
        dispatch(setLoading(false));
        dispatch(searchResults(data));
    }

    const searchError = (error) => {
        dispatch(setError(error));
        dispatchSearchResults([]);
    }

    /* actually calls the API */
    const doSearch = (queryText) => {
        dispatch(setSearchQuery(queryText))
        if (!!queryText) {
            if (isLoading && queryText === searchQuery) {
                console.log("duplicate, skipping");
            }
            console.log("doSearch", queryText, searchQuery, isLoading);
            dispatch(setLoading(true));
            dispatch(clearError());
            document.title = queryText + " - Rolling";
            let encodedQuery = encodeURIComponent(queryText)
            navigate('/cauta/' + encodedQuery, { replace: true }); // update bar
            ApiGet(`/search?q=${encodedQuery}&type=${searchType}`, dispatchSearchResults, searchError);
        } else {
            dispatch(setLoading(false));
            dispatch(clearError());
        }
    }

    // new search received from outside
    useEffect(() => {
        console.log("SearchBarDirect searchQuery:", searchQuery);
        setQueryRaw(searchQuery);
        doSearch(searchQuery);
    }, [searchQuery]);

    // searchType changed
    useEffect(() => {
        doSearch(searchQuery);
    }, [searchType]);

    useEffect(() => {
        if (!!queryDelayed) {
            console.log("Full Search (delayed): ", queryDelayed);
            const delayDebounceFn = setTimeout(() => {
                console.log("delayDebounceFn", queryDelayed)
                doSearch(queryDelayed);
            }, 1000)

            return () => clearTimeout(delayDebounceFn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryDelayed]);

    const navigateTo = (queryText) => {
        let encodedQuery = encodeURIComponent(queryText)
        navigate("/cauta/" + encodedQuery)
    }
    
    /* Enter or some key pressed */
    const onKeyDownSearchBar = (event) => {
        if (event.key === 'Enter') {
            console.log("Enter Search for:", queryRaw)
            navigateTo(queryRaw)
        }
    }


    /** 
     * Called every time somebody types in the rearch bar
     */
    const onChangeSearchText = (event) => {
        let rawQuery = event.target.value;
        console.log("onChangeSearchText", rawQuery)
        setQueryRaw(rawQuery);
        if (rawQuery.length >= 3) {
            setDelayedQuery(rawQuery)
        } else {
            dispatchSearchResults([]);
        }
    };

    /* Search Button pressed */
    const onClickSearchButton = () => {
        console.log("Click Search for:", queryRaw);
        navigateTo(queryRaw)
    }

    /* Search type radio changed */
    const onSearchTypeChange = (e) => {
        if (!e || !e.target || !e.target.id) {
            return;
        }
        console.log("SearchType changed", e.target.id);
        dispatch(setSearchType(e.target.id));
    }
    return (
        <InputGroup className="flex-row ml-auto mr-auto" id="searchGroup">
            <Form.Control
                id="search-bar"
                type="search"
                size="lg"
                className="flex-grow-1"
                aria-label="Search"
                placeholder="Caută după cod sau denumire ..."
                onChange={onChangeSearchText}
                onKeyDown={onKeyDownSearchBar}
                value={queryRaw}
            />

            <Form.Group id="searchType">
                <Form.Check
                    type="radio"
                    id="include"
                    label="conține"
                    name="searchType"
                    onChange={onSearchTypeChange}
                    checked={searchType === 'include'}
                />
                <Form.Check
                    type="radio"
                    id="starts"
                    label="începe"
                    name="searchType"
                    onChange={onSearchTypeChange}
                    checked={searchType === 'starts'}
                />
            </Form.Group>

            <Button
                className=""
                size="lg"
                variant="outline-secondary"
                id="search-button"
                onClick={onClickSearchButton}
            >
                <BsSearch /> <span id="searchButtonText">Caută</span>
            </Button>
        </InputGroup>
    )
}