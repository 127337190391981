import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { setError, setTempConfirmation } from "store/msgStore";
import { ApiPost } from "services/ApiService";
import LoadingSpinner from "components/common/LoadingSmall";

export default function AdminProductsImportEchivalents() {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const dispatch = useDispatch();

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setUploading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('action', 'import-equivalents');

        ApiPost('/catalog/products', formData,
            (dataOk) => {
                console.log("POST OK", dataOk);
                setUploading(false);
                dispatch(setTempConfirmation("Fisierul a fost procesat cu success."))
            },
            (error) => {
                setUploading(false);
                dispatch(setError(error));
            });
    };

    return (
        <>
            <h3>Import coduri echivalente</h3>
            {uploading ?
                (<LoadingSpinner />)
                :
                (
                    <Form onSubmit={handleSubmit}>
                        <input type="file" onChange={handleFileChange} />
                        <Button variant="primary" type="submit">Upload</Button>
                    </Form>
                )}

        </>
    )
}