import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { adminRoles } from "./Admin";
import { AiOutlineDashboard } from "react-icons/ai";

export default function AdminSidebar({ currentUser }) {


    return (

        <Nav className="flex-column" id="admin-menu">
            <h3><Nav.Link as={NavLink} to="/admin"><AiOutlineDashboard /> Admin dashboard</Nav.Link></h3>

            {!!adminRoles && adminRoles.map((item) => (

                !!item.section ? (
                    <h4 key={item.section}>{item.section}</h4>
                ) : (
                    !!item.path && currentUser.roles.includes(item.privilege) && (
                        <Nav.Link key={item.path} as={NavLink} to={item.path} title={item.text}>{item.icon} {item.title}</Nav.Link>
                    )
                )
            ))}

        </Nav>
    )
}