import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ApiDel } from "services/ApiService";
import { setTempConfirmation, setError } from "store/msgStore";


export default function AdminUserDelete({ user }) {
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const confirm = (e) => {
        console.log("confirm", e);
        setShow(true);
    }

    const handleClose = () => setShow(false);

    const userWasDeleted = (data) => {
        dispatch(setTempConfirmation("Utilizatorul a fost sters cu success."))
        navigate('/admin/users');
    }

    const doDelete = () => {
        ApiDel('/user/' + user.id, (data) => { userWasDeleted(data) }, (error) => { dispatch(setError(error)); });
        setShow(false);
    }


    return (
        <>
            <Button
                style={{ float: 'right' }}
                variant="danger"
                size="sm"
                title="Sterge"
                onClick={confirm}>
                Șterge utilizatorul
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-danger">Confirmați ștergerea</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sunteți sigur că doriți să ștergeți acest utilizator? <br />
                    Toate comenzile vor fi șterse din baza de date și nu vor mai putea fi recuperate! <br /><br />
                    O altă opțiune este să <em>inactivați</em> utilizatorul.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Anulează ștergerea
                    </Button>
                    <Button variant="danger" onClick={doDelete}>
                        ȘTERGE UTILIZATORUL
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}