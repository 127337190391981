import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { loadSimbolClase } from "store/productsReducer";
import { setError, clearError, setTempConfirmation } from "store/msgStore";
import { ApiGet, ApiPut } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';



export default function AdminSimbolClase({ curentUser }) {
    const simbolClase = useSelector((store) => store.products.simbolClase);
    const [data, setData] = useState([]); // need to do a deep copy

    let dispatch = useDispatch();

    const prepareData = () => {
        let newData = []
        Object.keys(simbolClase).map((key) => newData.push(simbolClase[key]));
        //dispatch(setLoading(false)); // TODO update directly in reducer
        setData(newData);
    }

    const refresh = () => {
        dispatch(clearError());
        //dispatch(setLoading(true));
        ApiGet('/catalog/clase/simboluri', (data) => { dispatch(loadSimbolClase(data)) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        prepareData();
    }, [simbolClase]);

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();
    }, []);

    const columns = [
        {
            dataField: 'id',
            text: '#',
            sort: true,
            editable: false,
            headerStyle: { width: '10%' }
        },
        {
            dataField: 'name',
            text: 'Nume in WinMentor',
            sort: true,
            editable: false
        }, {
            dataField: 'display',
            text: 'Afisare in site',
            sort: true,
            editable: true,
            validator: (newValue, row, column) => {
                if (!(newValue)) {
                    return {
                        valid: false,
                        message: 'Introduceti o valoare'
                    };
                }
                return true;
            }
        }]

    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];


    function beforeSaveCell(oldValue, newValue, row, column, done) {
        if (oldValue === newValue) {
            console.log("same value, ignore");
            done(false);
            return;
        }

        console.log("save", newValue, row.id)

        let payload = { id: row.id, display: newValue }

        ApiPut('/catalog/clase/simboluri', payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Valoarea [" + newValue + "] a fost salvata cu success."))
                refresh();
                done(true)
            },
            (error) => {
                dispatch(setError(error.message));
                done(false)
            });

        return { async: true };
    }


    return (
        <>
            <h1>Simbol Clase</h1>
            <p>Simbol clase sunt definite in Win-Mentor si importate ca atare. Aici puteti configura modul in care sunt afisate in site.</p>
            <p>Sunt in total {data.length} simbol-clase definite:</p>
            {(<BootstrapTable
                hover
                keyField='id'
                data={JSON.parse(JSON.stringify(data))}
                columns={columns}
                defaultSorted={defaultSorted}
                cellEdit={cellEditFactory({
                    mode: 'dbclick',
                    beforeSaveCell
                })}
                pagination={paginationFactory()}
            />
            )}
        </>
    );
}