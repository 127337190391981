
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import { Button, Card, ListGroup, ListGroupItem, Row, Tab, Tabs } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";



export default function AdminMiniCarousels({ currentUser }) {
    const [groups, setGroups] = useState([]);
    const [groupNames, setGroupNames] = useState([]);

    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/content/mini-carousels', (data) => { groupByName(data) }, (error) => { dispatch(setError(error)); });
    }

    const groupByName = (data) => {
        if (!data) {
            return;
        }

        let names = [];
        let groups = [];
        data.map((c) => {
            let key = c.name;
            if (!key) {
                key = "(fara nume)"
            }

            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(c);

            if (!names.find((c1) => (c1 === key))) {
                names.push(key);
            }
        })

        console.log(names, groups);
        setGroups(groups);
        setGroupNames(names);
    }

    useEffect(() => {
        refresh();
    }, []);

    useEffect(() => {
        // render
    }, [groups, groupNames]);

    const imageFormatter = (cell, row) => {
        return (
            !!row.image ? <img src={row.image} style={{ width: "30px", height: "30px" }} /> : ''
        )
    }

    const editFormatter = (cell, row) => {
        return (
            currentUser.roles.includes("pagini.edit") ?
                <Button as={NavLink} to={`/admin/pagini/${row.id}`}
                    variant="primary" size="sm" title="Modifica pagina">
                    <AiOutlineEdit />
                </Button>
                : <span>-</span>
        )
    }

    return (
        <>
            <h1>Mini-carousele</h1>
            <p className="text-muted">
                Acesta este conținut public care apare sub forma de mini-carousele, pentru logo-uri producători, parteneri, etc.
            </p>

            <Button as={NavLink}
                to={`/admin/mini-carousel/+`}
                className="mb-3"
                style={{ float: 'right' }}
                variant="outline-primary" size="sm" title="Adaugă">
                + Adaugă
            </Button>

            <Tabs
                id="carouselTabs"
                className="mb-3"
            >
                {!!groupNames && groupNames.map((n) => (
                    <Tab key={n}
                        eventKey={n}
                        title={n}>
                        <Row>
                            {groups[n].map(element => (
                                <div key={element.id} className="col d-flex align-items-stretch">
                                    <Card style={{ width: '10rem' }}>
                                        <Card.Img variant="top" src={element.image} />
                                        <ListGroup className="list-group-flush">
                                            <ListGroup.Item>
                                                <NavLink to={`/admin/mini-carousel/${element.id}`}
                                                    style={{ float: "right" }}><BiEdit /></NavLink>
                                                {!!element.title && (<h6><small>{element.title}</small></h6>)}
                                                <small><small>{element.description}</small></small>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </div>
                            ))}
                        </Row>
                    </Tab>
                ))}
            </Tabs>

        </>
    )
}