import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import PublicCarousel from 'components/public/PublicCarousel';
import { renderParagraph } from 'services/ParagraphRenderService';

function Home() {
  const pages = useSelector((store) => store.content.pages);
  const [homePage, setHomePage] = useState();

  useEffect(() => {
    if (!!pages && !!pages.map) {
      setHomePage(pages.find(p => p.path === 'home'))
    }
  }, [pages]);

  useEffect(() => {
    // render
  }, [homePage]);

  if (!!homePage && !!homePage.title) {
    document.title = homePage.title;
  }

  return (
    <>
      {homePage && <PublicCarousel currentPageId={homePage.id} />}

      <div className="mt-5 mb-5">
        {!!homePage && !!homePage.sumar &&
          <p className="mt-1 mb-3" style={{ fontSize: "1.2em" }}><em>{homePage.sumar}</em></p>}

        {!!homePage && !!homePage.content &&
          homePage.content.split("\n").map((p, index) => (
            renderParagraph(p, index)
          ))}
      </div>

    </>
  );
}

export default Home;