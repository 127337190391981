import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { loadShops } from "store/productsReducer";
import { setError, clearError, setTempConfirmation } from "store/msgStore";
import { ApiGet, ApiPut } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';


export default function AdminDepozite({ curentUser }) {
    const shops = useSelector((store) => store.products.shops);
    const [data, setData] = useState([]); // need to do a deep copy
    const [shopMapping, setShopMapping] = useState([]);

    let dispatch = useDispatch();

    const prepareData = () => {
        let newData = []
        Object.keys(shops).map((key) => newData.push(shops[key]));
        //dispatch(setLoading(false)); // TODO update directly in reducer
        setData(newData);
    }

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/shops/mapping', (data) => { setShopMapping(data) }, (error) => { dispatch(setError(error)); });
        ApiGet('/shops', (data) => { dispatch(loadShops(data)) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        prepareData();
        // eslint-disable-next-line
    }, [shops]);

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();
        // eslint-disable-next-line
    }, []);

    const emptyValidation = (newValue, row, column) => {
        if (!(newValue)) {
            return {
                valid: false,
                message: 'Introduceti o valoare'
            };
        }
        return true;
    }

    const columns = [
        {
            dataField: 'id',
            text: '#',
            sort: true,
            editable: false,
            headerStyle: { width: '10%' }
        }, {
            dataField: 'shortName',
            text: 'Prescurtare',
            sort: true,
            editable: true,
            validator: emptyValidation,
            headerStyle: { width: '20%' }
        }, {
            dataField: 'display',
            text: 'Nume afisat in site',
            sort: true,
            editable: true,
            validator: emptyValidation
        }, {
            dataField: 'description',
            text: 'Descriere completa',
            sort: true,
            editable: true,
            validator: emptyValidation
        }, {
            dataField: 'mapping',
            text: "Gestiuni",
            sort: true,
            editable: false
        }
    ]

    const injectMappings = (data) => {
        let newData = JSON.parse(JSON.stringify(data));
        return newData.map(r => ({ ...r, mapping: getMappingsFor(r.id) }));
    }

    const getMappingsFor = (shopId) => {
        return shopMapping
            .filter(m => parseInt(m.idShop) === parseInt(shopId))
            .map(m => m.gestiune)
            .join(' ');
    }

    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];


    function beforeSaveCell(oldValue, newValue, row, column, done) {
        if (oldValue === newValue) {
            console.log("same value, ignore");
            done(false);
            return;
        }

        let payload = { ...row }
        payload[column['dataField']] = newValue
        console.log("Save shop", payload);

        ApiPut('/shops', payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Valoarea [" + newValue + "] a fost salvata cu success."))
                refresh();
                done(true)
            },
            (error) => {
                dispatch(setError(error));
                done(false)
            });

        return { async: true };
    }


    return (
        <>
            <h1>Depozite</h1>
            <p>Depozitele sunt definite doar in site. Maparea se face cu gestiunile din WinMentor</p>
            {(<BootstrapTable
                hover
                keyField='id'
                data={injectMappings(data)}
                columns={columns}
                defaultSorted={defaultSorted}
                cellEdit={cellEditFactory({
                    mode: 'dbclick',
                    beforeSaveCell
                })}
                pagination={paginationFactory()}
            />
            )}
        </>
    );
}