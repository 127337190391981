import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { BsFillCartCheckFill, BsCartPlus } from 'react-icons/bs';
import { addToCart } from "store/cartStore";


export default function SearchResultsAddToCart({ product, getClasa, getSimbolClasa, getProducatori }) {
    const [quantity, setQuantity] = useState(1);
    const [addedToCart, setAddedToCart] = useState(false);

    const dispatch = useDispatch();

    const onChangeQuantity = (e) => {
        if (!!e.target.value) {
            let q = parseInt(e.target.value);
            console.log("Quantity changed", product.id, q)
            setQuantity(q);
        }
    }

    /* Select all to allow easy add */
    const onFocusQuantity = (event) => {
        const inputNode = event.target;
        inputNode.setSelectionRange(0, inputNode.value.length);
    }

    /* Add to cart button is pressed */
    const onButtonAddToCart = (event) => {
        console.log("Adding to cart", product.id, quantity, 1)
        setAddedToCart(true);
        dispatch(addToCart(
            {
                product: {
                    ...product,
                    // cache this
                    clasa: getClasa(product.idClasa),
                    simbolClasa: getSimbolClasa(product.idSimbolClasa),
                    producator: getProducatori(product.idProducator)
                },
                quantity: quantity,
                price: product.pretSite,
                boxQty: 1
            }));
    }

    return (
        <>
            <td className="col-qty">
                <Form.Control
                    className="qty-input"
                    onChange={onChangeQuantity}
                    onFocus={onFocusQuantity}
                    id={`add-to-cart-${product.id}`}
                    type="text"
                    defaultValue={1}
                    size="sm"
                    disabled={addedToCart}
                />
            </td>
            <td className="col-add">
                {addedToCart ? (
                    <BsFillCartCheckFill className="addCartConfirmed" />
                ) : (
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={onButtonAddToCart}
                    >
                        <BsCartPlus />
                    </Button>
                )}
            </td>
        </>
    )
}