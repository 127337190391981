
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Nav from 'react-bootstrap/Nav';
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { AiFillEye, AiOutlineEdit, AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import BootstrapTable from 'react-bootstrap-table-next';
import { BiCarousel } from "react-icons/bi";


export default function AdminPages({ currentUser }) {
    const [data, setData] = useState([]); // need to do a deep copy

    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/content/pages', (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        console.log("Pages", data);
        //render
    }, [data]);

    useEffect(() => {
        refresh();
    }, []);

    const activeFormatter = (cell, p) => {
        return (
            !!p.path ?
                (<Nav.Link key={p.path} as={NavLink} to={`/${p.path}`} >
                    {p.status == 1 ? <AiFillEye style={{ color: 'orange' }} /> : <AiOutlineEyeInvisible />}
                </Nav.Link>)
                :
                (p.status == 1 ? <AiOutlineEye style={{ color: 'green' }} /> : <AiOutlineEyeInvisible />)
        )
    }

    const carouselFormatter = (cell, row) => {
        return (
            parseInt(!!row.carouselPages && row.carouselPages.length) > 0 ? <BiCarousel style={{ color: 'green' }} /> : ''
        )
    }

    const imageFormatter = (cell, row) => {
        return (
            !!row.image ? <img src={row.image} style={{ width: "30px", height: "30px" }} /> : ''
        )
    }

    const editFormatter = (cell, row) => {
        return (
            currentUser.roles.includes("pagini.edit") ?
                <Button as={NavLink} to={`/admin/pagini/${row.id}`}
                    variant="primary" size="sm" title="Modifica pagina">
                    <AiOutlineEdit />
                </Button>
                : <span>-</span>
        )
    }

    const columns = [
        {
            dataField: 'menuOrder',
            text: '#',
            align: 'center',
            headerAlign: 'center',
            editable: false,
            headerStyle: { width: '5%' }
        },
        {
            dataField: 'status',
            text: 'Activ',
            editable: false,
            align: 'center',
            headerAlign: 'center',
            formatter: activeFormatter,
            headerStyle: { width: '5%' }
        },
        {
            dataField: 'carousel',
            text: "Car.",
            editable: false,
            align: 'center',
            headerAlign: 'center',
            formatter: carouselFormatter,
            headerStyle: { width: '5%' }
        },
        {
            dataField: 'image',
            text: "Img",
            editable: false,
            align: 'center',
            headerAlign: 'center',
            formatter: imageFormatter,
            headerStyle: { width: '5%' }
        },
        {
            dataField: 'title',
            text: 'Titlu pagina',
            //sort: true,
            editable: false,
            headerStyle: { width: '30%' }
        },
        {
            dataField: 'menu',
            text: 'Meniu',
            //sort: true,
            editable: false,
        },
        {
            dataField: 'path',
            text: 'URL',
            //sort: true,
            editable: false,
        },
        {
            dataField: 'block',
            text: 'Block',
            //sort: true,
            editable: false,
            headerStyle: { width: '10%' }
        },
        {
            dataField: 'edit',
            isDummyField: true,
            text: 'Modifica',
            align: 'center',
            headerAlign: 'center',
            //sort: false,
            editable: false,
            formatter: editFormatter,
            headerStyle: { width: '10%' }
        },
    ]


    const rowClasses = (row, rowIndex) => {
        let classes = null;

        if (row.status != 1) {
            classes = 'text-muted';
        }

        return classes;
    };


    return (
        <>
            <h1>Conținut public</h1>
            <p className="text-muted">
                Conținutul public poate fi prezentate în mai multe moduri: în pagini, în carousel și în casete (blocuri) inserate în alte pagini.
                <br />Imaginile se vor scala automat în funcție de locul unde apar.
                <br />Paginile care au link de meniu vor apărea în meniul principal.
                <br />Doar paginile active sunt afișate public.
            </p>

            <Button as={NavLink}
                to={`/admin/pagini/+`}
                className="mb-3"
                style={{ float: 'right' }}
                variant="outline-primary" size="sm" title="Adaugă">
                + Adaugă
            </Button>

            {!!data.map && (<BootstrapTable
                hover
                keyField='id'
                data={data}
                columns={columns}
                rowClasses={rowClasses}
                compact
            />
            )}
        </>
    )
}