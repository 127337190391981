import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";

function Loading() {
    const loadingStatus = useSelector((store) => store.msg.loading);

    useEffect(() => { }, [loadingStatus]);

    return (
        loadingStatus ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner className="mt-5" animation="border" variant="warning" size="xl" style={{ width: "8rem", height: "8rem" }} />
            </div>
        ) : ''
    );
}

export default Loading;