import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterByProducator } from "store/productsReducer";
import CheckboxList from "components/common/CheckboxList";
import { hashCode } from "common/utils";

function FilterProducatori() {
    const [options, setOptions] = useState([]);
    const producatori = useSelector((store) => store.products.producatori);
    const producatoriSelected = useSelector((store) => store.products.filters.producatoriSelected);
    const producatoriSelectedCounters = useSelector((store) => store.products.counters.producatoriSelected);

    const dispatch = useDispatch();

    useEffect(() => {
        // render
    }, [options]);

    useEffect(() => {
        let opt = [];
        if (!!producatoriSelectedCounters.map) {
            producatoriSelectedCounters.map((item) => {
                let selected = !!(producatoriSelected.find((i)=> parseInt(i) === parseInt(item.id)));
                opt.push({ name: producatori[item.id].display, count: item.count, id: item.id, selected: selected })
            });
        }
        
        setOptions(opt.sort((a,b)=>(b.count - a.count)));
        //console.log("FilterProducatori", options,producatoriSelected );
    }, [producatori, producatoriSelected, producatoriSelectedCounters]);

    const onSelectProducator = (e) => {
        console.log("onSelect", e.target.id, e.target.checked);
        dispatch(filterByProducator({ id: e.target.id, checked: e.target.checked }));
    }

    return (
        <CheckboxList label='Producători' options={options} onSelect={onSelectProducator} />
    );
}

export default FilterProducatori;
