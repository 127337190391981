import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setError, setTempConfirmation } from "store/msgStore";
import { ApiPut } from "services/ApiService";
import { GrAddCircle } from "react-icons/gr";


export default function AdminUserTypesAdd({ refresh }) {
    const dispatch = useDispatch();

    const doSave = (event) => {
        event.preventDefault();
        // code you want to do
        const name = event.target[0].value;
        const desc = event.target[1].value;

        console.log("addUserType: ", name, desc);

        const payload = { id: 0, name: name, desc: desc }

        ApiPut('/user-types-prod', payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Noul tip [" + name + "] a fost creat, nu uitati sa-l activati dupa ajustare."))
                refresh();
            },
            (error) => {
                dispatch(setError(error));
            });

        event.target.reset();
        window.scrollTo(0, 0);
    }

    return (
        <>
            <h4 className="mt-2"><GrAddCircle className="mb-1" style={{ color: 'orange' }} /> Adauga un nou tip de utilizator</h4>
            <Form onSubmit={doSave}>
                <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Nume</Form.Label>
                    <Form.Control type="text" placeholder="numele tipului" />
                    <Form.Text className="text-muted">
                        Acest nume va fi folosit atunci când aplicați drepturile și discounturile conturilor de parteneri.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formDescription">
                    <Form.Label>Descriere</Form.Label>
                    <Form.Control type="text" placeholder="descriere" />
                    <Form.Text className="text-muted">
                        O descriere mai detaliată, pentru referințe ulterioare
                    </Form.Text>
                </Form.Group>

                <Button variant="primary" type="submit">
                    Adaugă
                </Button>
            </Form>
        </>

    );
}