import axios from 'axios';
import authHeader from './auth-header';
import * as config from '../common/config';
import urlJoin from 'url-join';

function prepareAndUpdateProgress(progressEvent) {
    const totalLength = null;

    if (!!progressEvent.lengthComputable) {
        totalLength = progressEvent.total;
    } else if (!!progressEvent.target) {
        if (!!progressEvent.target.getResponseHeader('content-length')) {
            totalLength = progressEvent.target.getResponseHeader('content-length');
        } else if (!!progressEvent.target.getResponseHeader('x-decompressed-content-length')) {
            totalLength = progressEvent.target.getResponseHeader('x-decompressed-content-length');
        }
    }

    if (totalLength !== null) {
        let percentage = Math.round((progressEvent.loaded * 100) / totalLength);
        console.log("onUploadProgress", totalLength, percentage);
    } else {
        console.log("onUploadProgress - cannot get length", progressEvent);
    }
}

function ApiGet(path, dataSet, errorSet) {
    let url = urlJoin(config.API_URL, path);

    console.log("API-GET", url);
    axios.get(
        url,
        {
            headers: authHeader(),
            //   onDownloadProgress: prepareAndUpdateProgress
        }
    ).then((response) => {
        console.log("GET " + path + " response", response)
        dataSet(response.data);
    }).catch((error) => {
        console.log("Error on " + path, error);
        errorSet(error);
    })
}


function ApiPut(path, payload, dataSet, errorSet) {
    let url = urlJoin(config.API_URL, path);

    console.log("API-PUT", url);
    axios.put(
        url,
        payload,
        { headers: authHeader() }
    ).then((response) => {
        console.log("PUT " + path + " response", response)
        dataSet(response.data);
    }).catch((error) => {
        console.log("Error on " + path, error);
        errorSet(error);
    })
}

function ApiPost(path, payload, dataSet, errorSet) {
    let url = urlJoin(config.API_URL, path);

    console.log("API-POST", url);
    axios.post(
        url,
        payload,
        { headers: authHeader() }
    ).then((response) => {
        console.log("POST " + path + " response", response)
        dataSet(response.data);
    }).catch((error) => {
        console.log("Error on " + path, error);
        errorSet(error);
    })
}



function ApiDel(path, dataSet, errorSet) {
    let url = urlJoin(config.API_URL, path);

    console.log("API-DEL", url);
    axios.delete(
        url,
        { headers: authHeader() }
    ).then((response) => {
        console.log("DEL " + path + " response", response)
        dataSet(response.data);
    }).catch((error) => {
        console.log("Error on " + path, error);
        errorSet(error);
    })
}

export { ApiGet, ApiPost, ApiPut, ApiDel };
