import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Table, Col } from "react-bootstrap";
import CartPageQty from "./CartPage-Qty";
import CartPageDel from "./CartPage-Del";

export default function CartProducts() {
    const cart = useSelector((storePersisted) => storePersisted.cart.items);
    const [total, setTotal] = useState(0);


    useEffect(() => {
        let t = 0;
        cart.map((item) => (
            t += item.price * item.boxQty * item.quantity
        ))
        setTotal(t);
        console.log("Cart needs update, total=" + t);
    }, [cart]);

    if (!cart.length) {
        return (
            <p>Nu aveți produse în comandă.</p>
        )
    }


    return (
        <>
            <h5>Produse din comandă</h5>
            
            <Table id="searchResults" striped bordered hover size="sm" responsive="sm">
                <thead>
                    <tr>
                        <th className="align-middle text-center py-2">Cod</th>
                        <th className="align-middle text-center py-2">Denumire</th>
                        <th className="align-middle text-center py-2">Tip</th>
                        <th className="align-middle text-center py-2">Categorie</th>
                        <th className="align-middle text-center py-2">Producator</th>
                        <th className="align-middle text-center py-2">UM</th>
                        <th className="align-middle text-center py-2">Pret</th>
                        <th className="align-middle text-center py-2">Cantitate</th>
                        <th className="align-middle text-center py-2">Total</th>
                        <th className="align-middle text-center py-2"> </th>
                    </tr>
                </thead>
                <tbody>
                    {!!cart && cart.map((item) => (
                        <tr key={`${item.key}-${item.boxQty}`}>
                            <td>{item.product.codExtern}</td>
                            <td>{item.product.Denumire}</td>
                            <td>{item.product.clasa}</td>
                            <td>{item.product.simbolClasa}</td>
                            <td>{item.product.producator}</td>
                            {(parseInt(item.boxQty) === 1) ? (
                                <td className="align-middle text-center">{item.product.um}</td>
                            ) : (
                                <td className="text-center" style={{ width: '7%' }}>{item.bulkName}<br /><small>[{item.boxQty} {item.product.um}]</small></td>
                            )}
                            <td className="col-price">{item.product.promotie==='1' && (<span style={{color:'green', fontSize:'.8em'}}>PROMOTIE<br/></span>)} {item.price}</td>
                            <td className="col-qty"> <Col> <CartPageQty item={item} className="mr-1" /></Col></td>
                            <td className="col-price"><strong>{item.total.toFixed(2)}</strong></td>
                            <td className="align-middle text-center"><Col> <CartPageDel item={item} /></Col></td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={8} style={{ textAlign: 'right' }}>
                            <strong><big>Total comandă:</big></strong>
                        </td>
                        <td className='align-right col-price'>
                            <strong>{total.toFixed(2)}</strong> &euro;
                        </td>
                        <td colSpan={1} ></td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}