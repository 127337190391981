import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setLoading, setError, clearError, setTempConfirmation } from "store/msgStore";
import { Badge } from "react-bootstrap";
import { ApiGet, ApiPut } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import AdminProductsImportEchivalents from "./AdminProducts-ImportEchivalents";
import { FiFolderMinus, FiFolderPlus, } from "react-icons/fi";
import AdminProductEquivalents from "./AdminProduct-Equivalents";
import { Tabs, Tab } from "react-bootstrap";
import AdminProductEnGros from "./AdminProduct-EnGros";
import AdminProductFiles from "./AdminProduct-Files";
import AdminProductPreturi from "./AdminProduct-Preturi";

export default function AdminProducts({ curentUser }) {
    const clase = useSelector((store) => store.products.clase);
    const producatori = useSelector((store) => store.products.producatori);
    const simbolClase = useSelector((store) => store.products.simbolClase);
    const shops = useSelector((store) => store.products.shops);

    const [data, setData] = useState([]); // need to do a deep copy

    let dispatch = useDispatch();

    const prepareData = (loadedData) => {
        //let newData = []
        // Object.keys(loadedData).map((key) => newData.push(loadedData[key]));
        dispatch(setLoading(false));
        // setData(newData);
        setData(loadedData);
    }

    const refresh = () => {
        console.log("loading products");
        dispatch(clearError());
        dispatch(setLoading(true));
        ApiGet('/catalog/products', (data) => { prepareData(data) }, (error) => { dispatch(setLoading(false)); dispatch(setError(error)); });
    }

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();
    }, []);

    const columns = [
        {
            dataField: 'codArticol',
            text: 'Cod Articol',
            sort: true,
            editable: false,
            filter: textFilter(),
            headerStyle: { width: '10%' }
        },
        {
            dataField: 'codExtern',
            text: 'Cod Extern',
            sort: true,
            editable: false,
            filter: textFilter(),
            headerStyle: { width: '20%' }
        },
        {
            dataField: 'codCatalog',
            text: 'Cod Catalog',
            sort: true,
            editable: false,
            filter: textFilter(),
        }, 
        {
            dataField: 'um',
            text: 'U.M.',
            sort: true,
            editable: false,
            filter: textFilter(),
            headerStyle: { width: '6%' }
        },
        {
            dataField: 'pretSite',
            text: 'Pret',
            sort: true,
            editable: false,
            filter: textFilter(),
            headerStyle: { width: '6%' },
            align: 'right'
        },
        {
            dataField: 'promotie',
            text: 'Pr.',
            sort: true,
            editable: false,
            filter: numberFilter({
                defaultValue: { comparator: Comparator.GE },
                placeholder: '-',
            }),
            headerStyle: { width: '60px' }
        },
        {
            dataField: 'nrEg',
            text: 'EnGr.',
            sort: true,
            editable: false,
            filter: numberFilter({
                defaultValue: { comparator: Comparator.GE },
                placeholder: '-',
            }),
            headerStyle: { width: '60px' }
        },
        {
            dataField: 'nrEq',
            text: 'Echiv.',
            sort: true,
            editable: false,
            filter: numberFilter({
                defaultValue: { comparator: Comparator.GE },
                placeholder: '-',
            }),
            headerStyle: { width: '60px' }
        },
        {
            dataField: 'nrFiles',
            text: 'Fișe T.',
            sort: true,
            editable: false,
            filter: numberFilter({
                defaultValue: { comparator: Comparator.GE },
                placeholder: '-',
            }),
            headerStyle: { width: '60px' }
        }]

    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];

    const expandRow = {
        renderer: row => (
            <>
                <h3>{row.Denumire} <Badge pill bg="info" >{row.codExtern}</Badge></h3>
                <p className="text-muted">{row.codIntern}</p>
                <Tabs
                    defaultActiveKey="prices"
                    id={`productTabs-${row.codArticol}`}
                    className=""
                >
                    <Tab eventKey="prices" title="Prețuri" className="bg-light p-3">
                        {<AdminProductPreturi product={row} />}
                    </Tab>
                    <Tab eventKey="enGros" title="En-Gros" className="bg-light p-3">
                        {<AdminProductEnGros product={row} />}
                    </Tab>
                    <Tab eventKey="productEquivalents" title="Coduri echivalente"  className="bg-light p-3">
                        {<AdminProductEquivalents product={row} />}
                    </Tab>
                    <Tab eventKey="productFiles" title="Fișe tehnice"  className="bg-light p-3">
                        {<AdminProductFiles product={row} />}
                    </Tab>
                </Tabs>
            </>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return (<></>);
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<FiFolderMinus />);
            }
            return (<FiFolderPlus />);
        }
    };

    function beforeSaveCell(oldValue, newValue, row, column, done) {
        if (oldValue === newValue) {
            console.log("same value, ignore");
            done(false);
            return;
        }

        console.log("save", newValue, row.id)

        let payload = { id: row.id, display: newValue }

        ApiPut('/catalog/product', payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Valoarea [" + newValue + "] a fost salvata cu success."))
                refresh();
                done(true)
            },
            (error) => {
                dispatch(setError(error));
                done(false)
            });

        return { async: true };
    }


    return (
        <>
            <h1>Produse</h1>
            <p>Informatiile despre produse importate din Win-Mentor sunt afisate ca atare.
                Suplimentar se pot configura codurile echivalente, fisa produsului</p>
            <p>Sunt in total {data.length} produse definite:</p>
            {!!data && !!data.map && (<BootstrapTable
                hover
                keyField='codArticol'
                data={JSON.parse(JSON.stringify(data))}
                columns={columns}
                defaultSorted={defaultSorted}
                cellEdit={cellEditFactory({
                    mode: 'dbclick',
                    beforeSaveCell
                })}
                filter={filterFactory()}
                pagination={paginationFactory()}
                expandRow={expandRow}
                condensed
            />
            )}
            <hr />
            <AdminProductsImportEchivalents />
        </>
    );
}