import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ApiGet } from "services/ApiService";
import { setError, clearError } from "store/msgStore";

import BootstrapTable from 'react-bootstrap-table-next';


/*
        "id": 34,
        "idMentor": 0,
        "denumire": "Sediu firma",
        "codSediu": 9844,
        "localitate": "TIMISOARA",
        "strada": "",
        "sector": "",
        "numar": "",
        "numar2": "",
        "bloc": "",
        "scara": "",
        "etaj": "",
        "ap": "",
        "judet": "TM",
        "codPostal": "",
        "email": "",
        "telefon": "",
        "tipSediu": "SLF",
        "agent": "",
        "idPartener": 107
        */
export default function AdminParteneriContacte({ partener }) {
    const [data, setData] = useState([]); // need to do a deep copy

    let dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        //dispatch(setLoading(true));
        ApiGet('/parteneri/contacte/' + partener.id, (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();
    }, []);

    /*
    [{"id":47,"idPartener":113,"nume":"GOIA","prenume":"MARIAN","telefon":"","email":"","functie":"ADMINISTRATOR","idMentor":0}]
    */
    const columns = [
        {
            dataField: 'functie',
            text: 'Funcție',
        },
        {
            dataField: 'nume',
            text: 'Nume',
        },
        {
            dataField: 'prenume',
            text: 'Prenume',
        },
        {
            dataField: 'telefon',
            text: 'Telefon',
        },
        {
            dataField: 'email',
            text: 'e-Mail',
        }
    ]

    //https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
    return (
        <>
            <h4>Persoane de contact</h4>

            {(<BootstrapTable
                hover
                keyField='id'
                data={JSON.parse(JSON.stringify(data))}
                columns={columns}
                condensed
            />
            )}
        </>
    );
}