import { logout } from "../../services/AuthService";

function Logout() {
    logout();
    localStorage.clear();

    //TODO: try to useNavigate to make it smoother, but still reload App.js component
    //let navigate = useNavigate();
    //navigate('/');
    window.location.href = '/';
}

export default Logout;