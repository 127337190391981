import Card from 'react-bootstrap/Card';
import { NavLink } from "react-router-dom";

function CardItem({ title, subtitle, text, linkName, linkTo }) {
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
        <Card.Text>
          {text}
        </Card.Text>
        <Card.Link as={NavLink} to={linkTo}>{linkName}</Card.Link>
      </Card.Body>
    </Card>
  );
}

export default CardItem;