import { useState } from "react";
import { ApiPost } from "services/ApiService";
import { NavLink } from "react-router-dom";
import { Form, Container, Card, Row, Col, Button } from "react-bootstrap";

export default function LostPass() {
    const [username, setUsername] = useState('');
    const [response, setResponse] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log("Recover password for ", username);
        ApiPost('/lostpass', { username: username },
            (data) => {
                setResponse(true);
            },
            (error) => {
                setResponse(true);
            });
    }

    const onChangeUsername = e => {
        setUsername(e.target.value);
    }

    return (
        <Container>
            <Row className="vh-100 d-flex justify-content-center">
                <Col md={6} lg={4} xs={12}>
                    <Card className="shadow mt-5">
                        <Card.Body>
                            <div className="mb-3 mt-md-4">
                                <h5 className="mb-4">Recuperează parola</h5>
                                {response
                                    ? (<>
                                            <p className="text-success mb-5">
                                                Dacă există un cont asociat acestei adrese de email, veți primi un mesaj cu instrucțiunile de recuperare a parolei.
                                            </p>
                                            <div className="form-group mt-3 text-center">
                                                <NavLink to="/login">Login B2B</NavLink>
                                            </div>
                                        </>)
                                    : (
                                        <div className="mb-3">
                                            <Form onSubmit={handleSubmit}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label className="text-left mb-3">
                                                        Introduceți adresa de email pe care o folosiți pentru a vă autentifica pe platforma B2B
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="Adresa de email"
                                                        value={username}
                                                        onChange={onChangeUsername} />
                                                </Form.Group>

                                                <div className="d-grid mt-4">
                                                    <Button variant="warning" type="submit" >
                                                        Recuperează parola
                                                    </Button>
                                                </div>
                                            </Form>

                                        </div>
                                    )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}