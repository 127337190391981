import { Carousel, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useRef } from "react";


export default function PublicMiniBanner({ carouselName }) {
    const miniCarousels = useSelector((store) => store.content.miniCarousels);

    const wrapper = useRef();
    const sign = useRef(1);


    if (!miniCarousels) {
        return (<></>)
    }

    if (!miniCarousels[carouselName]) {
        return (<></>)
    }

    return (
        <Container className="minibanner" style={{ width: "90%" }} >
            <Row>
                {miniCarousels[carouselName].map((c) => (
                    <Col key={c.id} className="item" >
                        <img
                            height="50px"
                            src={c.image}
                            alt={c.title}
                        />
                        <div>
                            <h4>{c.title}</h4>
                            <span className="desc">{c.description}</span>
                        </div>
                    </Col>))}
            </Row>
        </Container>
    )

}