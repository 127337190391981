import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function SearchResultsStoc({ product, shops, nr = 1 }) {
    // Combine the stock info for all shops into one string
    const shopStockInfo = shops.map((s) => {
        let stockForThisShop = product.stock.find((k) => k.idShop === s.id);
        if (!!stockForThisShop) {
            return (
                <div key={s.id} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span>{s.shortName}</span>
                    <span>{stockForThisShop.stock}</span>
                </div>
            );
        }
        return null;
    }).filter(Boolean); // Filter out null values and join them with a comma

    // Combine all shop descriptions into one string
    const shopDescriptions = shops.map((s) => {
    
        let stockForThisShop = product.stock.find((k) => k.idShop === s.id);
        if (!!stockForThisShop) {
            return ( 
                <div key={s.id} style={{ display: 'flex',flexWrap: 'nowrap', width: '100%', marginBottom: '2px', marginTop:'2px'}}>
                    <span style={{flex: '0 0 50px', paddingRight: '5px', textAlign:"right", whiteSpace: 'nowrap', fontWeight: 'bold'}}>{stockForThisShop.stock} {product.um}</span>
                    <span style={{ textAlign:"left", whiteSpace: 'nowrap', paddingRight: '5px'}}>{s.display}</span>
                </div>
                
            )
        }
        return null;
    })
        .filter(Boolean); // Filter out null values and join them 



    return (
        <td className="col-stock" rowSpan={nr}>
            {!!shopStockInfo && (
                <OverlayTrigger
                    placement="bottom"
                    style={{whiteSpace: 'nowrap'}}
                    overlay={
                        <Tooltip id={`tooltip-shops-stock-${product.id}`} className='stock-tooltip' style={{}}>
                           {shopDescriptions}
                        </Tooltip>
                    }
                >
                    <div>
                        {shopStockInfo}
                    </div>

                </OverlayTrigger>
            )}
        </td>
    );
}
