import { Card, Form, Button } from "react-bootstrap"
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ApiPost } from "services/ApiService";
import { setLoading, setTempConfirmation, clearError, setError } from "store/msgStore";


export default function AdminParteneriImportProducatori() {
    const [file, setFile] = useState(null);
    const [data, setData] = useState(null);

    let dispatch = useDispatch();
    
    const onChangeFile = (e) => {
        console.log("File", e.target.files);
        setFile(e.target.files[0]);
    }

    
    const savePage = (e) => {
        e.preventDefault();
        dispatch(setLoading(true));

        const formData = new FormData();

        formData.append('prod', file);

        ApiPost('/parteneri/producatori',
            formData,
            (data) => {
                dispatch(setLoading(false));
                dispatch(setTempConfirmation("Lista a fost procesata"));
                setData(data);
            },
            (error) => {
                dispatch(setLoading(false));
                dispatch(setError(error));
            });
    }
    
    useEffect(()=>{
        // render
    }, [data])
    
    return (
        !!data ?
        data.map((p, idx)=>(
            <li key={idx}>{p.name}: {p.status}</li>
        ))
        : <Card className="p-0">
            <Card.Body>
                <Card.Title>Import lista parteneri-producatori</Card.Title>
 
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Lista parteneri-producatori</Form.Label>
                    <Form.Control type="file" onChange={onChangeFile} />
                    <Form.Text className="text-muted">
                        Lista CSV cu numele partenerilor de tip producatori (unul pe linie)
                    </Form.Text>
                </Form.Group>

                <Button className="ml-3" onClick={savePage} variant='success'>Importa lista</Button>
            </Card.Body>
        </Card >
    )
}