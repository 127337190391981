import { useState } from "react";
import { Badge, Button, Form } from "react-bootstrap";
import { Collapse } from "react-bootstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

export default function CheckboxList({ label, options, onSelect }) {
    const [open, setOpen] = useState(false);

    const renderLabel = (p) => {
        return (
            <small>
                {p.name}
                <Badge
                    style={{ fontSize: '.8em', border: '1px solid orange', marginLeft: '5px', verticalAlign: 'text-bottom' }}
                    size='sm'
                    pill
                    bg="light"
                    text="secondary" >
                    {p.count}
                </Badge>
            </small>
        )
    }
    
    let slice1 = options.slice(0, 6);
    let slice2 = options.slice(6);
    return (
        <Form.Group className="mb-3 small" controlId={`form-${label}`}>
            <Form.Label>{label}</Form.Label>

            {!!slice1 && !!slice1.map && slice1.map((p) => (
                <Form.Check key={p.id}
                    className='facet'
                    type="switch"
                    id={p.id}
                    checked={p.selected}
                    label={renderLabel(p)}
                    onChange={onSelect}
                />
            ))}

            {!!slice1 && slice1.length > 0 && !!slice2 && slice2.length>0 && !!slice2.map && (
                <>
                    {!open && <Button
                        style={{float:'right', fontSize:'0.8em', color:'orange', textDecoration:'none'}}
                        variant="link"
                        onClick={() => setOpen(!open)}
                        aria-controls="foreChecks"
                        aria-expanded={open}
                        size="sm"
                        bg="none"
                    >
                        mai mult <BsChevronDown />
                    </Button> }
                    <Collapse in={open}>
                        <div id="moreChecks">
                            {slice2.map((p) => (
                                <Form.Check key={p.id}
                                    className='facet'
                                    type="switch"
                                    id={p.id}
                                    label={renderLabel(p)}
                                    onChange={onSelect}
                                />
                            ))}
                        </div>
                    </Collapse>
                    {open && <Button
                        style={{float:'right', fontSize:'0.8em', color:'orange', textDecoration:'none'}}
                        variant="link"
                        onClick={() => setOpen(!open)}
                        aria-controls="foreChecks"
                        aria-expanded={open}
                        size="sm"
                        bg="none"
                    >
                        mai puțin <BsChevronUp />
                    </Button> }
                </>
            )}
        </Form.Group>
    )
}