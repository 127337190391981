import { useState, useEffect } from "react";
import { Button, Card, ListGroup, Row, Col } from "react-bootstrap";


import { ApiGet, ApiPut } from "../../../services/ApiService";
import { epochToLocal } from "../../../common/utils";

import AlertBox from "../../common/AlertBox";
import LoadingSpinner from "../../common/LoadingSmall";

export default function AdminCatalogImport({ currentUser }) {

    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [downloadStarted, setDownloadStarted] = useState(false);

    useEffect(() => {
        refreshStatus();
    }, []);

    useEffect(() => {
    }, [data, error]);

    if (error) {
        return (
            <AlertBox message={data} />
        )
    }

    if (!data) {
        return (
            <LoadingSpinner />
        )
    }

    const startDownload = () => {
        setDownloadStarted(true);
        ApiPut('/catalog/download', { action: 'download' }, setData, setError);
    }


    const startImport = () => {
        //setDownloadStarted(true);
        ApiPut('/catalog/import', { action: 'import' }, setData, setError);
    }

    const refreshStatus = () => {
        ApiGet('/catalog/status', setData, setError);
    }

    return (
        <>
            <Card className="p-0">
                <Card.Body>
                    <Card.Title>Produse existente in DB</Card.Title>
                </Card.Body>
                <ListGroup variant="flush">
                    {!!(data) &&
                        !!(data.totals) && (
                            <ListGroup.Item>Produse existente in baza de date: <strong>{!!(data.totals.products) ? data.totals.products : "-"}</strong> </ListGroup.Item>
                        )}
                </ListGroup>

            </Card>

            <Row xs={1} md={2} className="g-4">

                <Col>
                    <Card className="p-0">
                        <Card.Body>
                            <Card.Title>Import catalog</Card.Title>
                        </Card.Body>

                        {!!(data) &&
                            !!(data.import) && (
                                <>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>Inceput import: {!!(data.import.start) ? epochToLocal(data.import.start) : "-"}</ListGroup.Item>
                                        <ListGroup.Item>Sfarsit import: {!!(data.import.end) ? epochToLocal(data.import.end) : "-"}</ListGroup.Item>
                                        <ListGroup.Item>Produse importate: {!!(data.import.products) ? data.import.products : "-"}</ListGroup.Item>
                                    </ListGroup>
                                    <Card.Body>
                                        <Button onClick={refreshStatus}>Refresh</Button>
                                        <Button className="ml-3" onClick={startImport} variant='warning'>Import now</Button>
                                    </Card.Body>
                                </>
                            )}
                    </Card >
                </Col>
                <Col>
                    <Card className="p-0">
                        <Card.Body>
                            <Card.Title>Download catalog</Card.Title>
                        </Card.Body>

                        {!!(data) &&
                            !!(data.download) && (
                                <>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>Ultima descarcare de articole.json: {!!(data.download.end) ? epochToLocal(data.download.end) : "never"}</ListGroup.Item>
                                        {(data.download.start) && (
                                            <ListGroup.Item>Descarcarea a inceput la: {epochToLocal(data.download.start)} </ListGroup.Item>
                                        )}
                                        <ListGroup.Item>JSON temporar disponibil: {!!(data.download.fileExists) ? "da" : "nu"}</ListGroup.Item>
                                        <ListGroup.Item>Ultima modificare: {!!(data.download.lastMod) ? epochToLocal(data.download.lastMod) : "n/a"} (acum {!!(data.download.diff) ? (data.download.diff + " ore") : "-"})</ListGroup.Item>
                                        <ListGroup.Item>Marime fisier: {!!(data.download.fileSize) ? data.download.fileSize : "-"}</ListGroup.Item>
                                    </ListGroup>
                                    <Card.Body>
                                        <Button onClick={startDownload} disabled={downloadStarted || !(data.download.end)} variant='primary'>Dowload now</Button>
                                    </Card.Body>
                                </>
                            )}
                    </Card>
                </Col>
            </Row>
        </>
    )
}
