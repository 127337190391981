import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Alert, Fade } from "react-bootstrap";


function TemporaryMessage() {
    const [msg, setMessage] = useState("");
    const [show, setShow] = useState(false);
    const tempMessage = useSelector((store) => store.msg.tempConfirmation);

    useEffect(() => { }, [msg, show]);

    useEffect(() => {
        if (!!tempMessage) {
            setMessage(tempMessage);
            setShow(true);
            setTimeout(() => {
                setShow(false); // start fade out
            }, 2000);

            setTimeout(() => {
                setMessage(''); // clear after 2 min
            }, 3000);

        } else {
            setShow(false);
        }
    }, [tempMessage]);

    return (
        !!msg && (
            <Fade appear={true} in={!show} timeout={500}>
                <Alert className="mt-3" variant='success' style={{ position: "absolute", right: 20 }}>
                    {msg}
                </Alert>
            </Fade>
        )
    );
}

export default TemporaryMessage;