import { createSlice } from '@reduxjs/toolkit';

const contentStore = createSlice({
    name: 'content',
    initialState: {
        pages: [],
        blocks: [],
        miniCarousels: []
    },
    reducers: {
        setPages(state, action) {
            console.log("setPages", action.payload);
            let blocks = [];
            action.payload
                .filter(p => !!p.block)
                .map(b => blocks[b.block] = b);
                
            console.log("setBlocks", blocks);
            state.blocks = blocks;
            state.pages = action.payload;
        },
        
        setMiniCarousels(state, action) {
            console.log("setMiniCarousels", action.payload);
            state.miniCarousels = action.payload;
        },
    }
})

export const {
    setPages, setMiniCarousels
} = contentStore.actions
export default contentStore.reducer