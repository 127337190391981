import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

export default function AdminUserAddParteneri({ markSelected }) {
    const [data, setData] = useState([]); // need to do a deep copy
    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/parteneri', (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();
    }, []);

    const selectOptions = {
        'NU': 'Nu',
        'DA': 'Da',
    };

    const columns = [
        {
            dataField: 'cod',
            text: 'Cod',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
                if (order === 'desc') {
                    return parseInt(b) < parseInt(a);
                }
                return parseInt(b) > parseInt(a); // desc
            },
            editable: false,
            filter: textFilter({ placeholder: 'filtru...' }),
            headerStyle: { width: '10%' }
        },
        {
            dataField: 'persoanaFizica',
            text: 'PF',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: selectOptions
            }),
            editable: false,
            headerStyle: { width: '10%' }
        },
        {
            dataField: 'denumire',
            text: 'Denumire',
            sort: true,
            filter: textFilter({ placeholder: 'filtru...' }),
            editable: false
        },
        {
            dataField: 'codFiscal',
            text: 'Cod fiscal',
            sort: true,
            filter: textFilter({ placeholder: 'filtru...' }),
            editable: false
        },
        {
            dataField: 'registruComert',
            text: 'Reg. Comert',
            sort: true,
            filter: textFilter({ placeholder: 'filtru...' }),
            editable: false
        },
        {
            dataField: 'observatii',
            text: 'Observatii',
            sort: true,
            filter: textFilter({ placeholder: 'filtru...' }),
            editable: false
        },
        {
            dataField: 'caracCont',
            text: 'Caracterizare contabila',
            sort: true,
            filter: textFilter({ placeholder: 'filtru...' }),
            editable: false
        },
        {
            dataField: 'blocat',
            text: 'Blocat',
            sort: true,
            filter: selectFilter({
                placeholder: 'oricare',
                options: selectOptions
            }),
            editable: false,
            headerStyle: { width: '10%' }
        }
    ]

    const customTotal = (from, to, size) => (
        <span style={{ paddingLeft: "3em" }} className="small text-muted react-bootstrap-table-pagination-total">
            rândurile {from} .. {to} / {size}
        </span>
    );

    const paginationOptions = {
        showTotal: true,
        paginationTotalRenderer: customTotal,
        paginationSize: 5,
        pageStartIndex: 1,
        alwaysShowAllBtns: true, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'Prima pagină',
        //prePageText: '|<',
        //nextPageText: '>|',
        lastPageText: 'Ultima',
        nextPageTitle: 'Următoarea pagină',
        prePageTitle: 'Pagina precedentă',
        firstPageTitle: 'Prima pagină',
        lastPageTitle: 'Ultima pagină',
    }

    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log('partener selected', row, isSelect)
            markSelected(row);
        }
    };

    return (
        <>
            <em>Alegeți partenerul din listă:</em>
            <BootstrapTable
                hover
                keyField='id'
                selectRow={selectRow}
                data={JSON.parse(JSON.stringify(data))}
                columns={columns}
                defaultSorted={defaultSorted}
                pagination={paginationFactory(paginationOptions)}
                filter={filterFactory()}
                filterPosition="top"
                condensed
            // striped
            />
        </>
    )
}