import { useState, useEffect } from "react";
import { Button, Card, ListGroup, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setError, setLoading } from "store/msgStore";
import { ApiGet, ApiPut } from "services/ApiService";
import { epochToLocal } from "common/utils";

import AlertBox from "components/common/AlertBox";
import LoadingSpinner from "components/common/LoadingSmall";

export default function AdminStockImport({ currentUser }) {
    const [data, setData] = useState([]);
    const [downloadStarted, setDownloadStarted] = useState(false);

    const dispatch = useDispatch();
    
    useEffect(() => {
        refreshStatus();
    }, []);

    useEffect(() => {
    }, [data]);

    const startDownload = () => {
        setDownloadStarted(true);
        dispatch(setLoading(true));
        ApiPut('/catalog/stock/download', { action: 'download' }, 
        (data) => {
            dispatch(setLoading(false));
            setData(data);
        }, 
        (error) => {
            dispatch(setError(error));
            dispatch(setLoading(false));
        });
    }

    const startImport = () => {
        dispatch(setLoading(true));
        ApiPut('/catalog/stock/import', { action: 'import' },  
        (data) => {
            dispatch(setLoading(false));
            setData(data);
        },  
        (error) => {
            dispatch(setError(error));
            dispatch(setLoading(false));
        });
    }

    const refreshStatus = () => {
        ApiGet('/catalog/stock/status', setData, setError);
    }

    return (
        <>
            <Row xs={1} md={2} className="g-4">

                <Col>
                    <Card className="p-0">
                        <Card.Body>
                            <Card.Title>Importa stocuri</Card.Title>
                        </Card.Body>

                        {!!(data) &&
                            !!(data.import) && (
                                <>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>Inceput import: {!!(data.import.start) ? epochToLocal(data.import.start) : "-"}</ListGroup.Item>
                                        <ListGroup.Item>Sfarsit import: {!!(data.import.end) ? epochToLocal(data.import.end) : "-"}</ListGroup.Item>
                                        <ListGroup.Item variant="success">Produse importate: {!!(data.import.products) ? data.import.products : "-"}</ListGroup.Item>
                                        <ListGroup.Item variant="warning">Produse neimportate: {!!(data.import.skipped) ? data.import.skipped : "-"}</ListGroup.Item>
                                    </ListGroup>
                                    <Card.Body>
                                        <Button className="mx-3" onClick={refreshStatus}>Refresh</Button>
                                        <Button className="mx-3" onClick={startImport} variant='warning'>Importă acum</Button>
                                    </Card.Body>
                                </>
                            )}
                    </Card >
                </Col>

                <Col>
                    <Card className="p-0">
                        <Card.Body>
                            <Card.Title>Descarca stocurile</Card.Title>
                        </Card.Body>

                        {!!(data) &&
                            !!(data.download) && (
                                <>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>Ultima descarcare de stock.json: {!!(data.download.end) ? epochToLocal(data.download.end) : "never"}</ListGroup.Item>
                                        {(data.download.start) && (
                                            <ListGroup.Item>Descarcarea a inceput la: {epochToLocal(data.download.start)} </ListGroup.Item>
                                        )}
                                        <ListGroup.Item>JSON temporar disponibil: {!!(data.download.fileExists) ? "da" : "nu"}</ListGroup.Item>
                                        <ListGroup.Item>Ultima modificare: {!!(data.download.lastMod) ? epochToLocal(data.download.lastMod) : "n/a"} {!!(data.download.diff) ? ("(acum " + data.download.diff + " ore)") : ""}</ListGroup.Item>
                                        <ListGroup.Item>Marime fisier: {!!(data.download.fileSize) ? data.download.fileSize : "-"}</ListGroup.Item>
                                    </ListGroup>
                                    <Card.Body>
                                        <Button onClick={startDownload} disabled={downloadStarted} variant='primary'>Dowload now</Button>
                                    </Card.Body>
                                </>
                            )}
                    </Card>
                </Col>

            </Row>
        </>
    )
}
