import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { Container } from "react-bootstrap";

import Login from "./components/user/Login";
import Logout from "./components/user/Logout";
import Home from "./components/static/Home";
import UserProfile from "./components/user/UserProfile";
import { Admin } from "./components/admin/Admin";

import MenuBar from "./components/MenuBar";
import SearchResults from "components/products/SearchResults";
import Search from "components/Search";
import Footer from "components/Footer";
import CartPage from "components/cart/CartPage";
import Contact from "components/static/Contact";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import PublicPage from "components/public/PublicPage";
import { setMiniCarousels, setPages } from "store/contentStore";
import { getCurrentUser, startRefreshTimer } from "services/AuthService";
import LostPass from "components/user/password/LostPass";
import ResetPass from "components/user/password/ResetPass";
import ChangePasswordForm from "components/user/password/ChangePasswordForm";
import UserLocationForm from "components/user/locations/UserLocationForm";

function App() {
  const pages = useSelector((store) => store.content.pages);
  const [user, setUser] = useState();

  const dispatch = useDispatch();
  
  const location = useLocation();  // Get the current location

  // Modify the path to be safe as a class name (replace slashes with dashes, remove special characters, etc.)
  const pathClassName = location.pathname.replace(/\//g, '-').replace(/[^a-zA-Z0-9-]/g, '');


  const refreshPages = () => {
    dispatch(clearError());
    ApiGet('/pub/pages', (data) => { dispatch(setPages(data)) }, (error) => { dispatch(setError(error)); });
    ApiGet('/pub/minicarousels', (data) => { dispatch(setMiniCarousels(data)) }, (error) => { dispatch(setError(error)); });
  }

  useEffect(() => {
    refreshPages();

    let currentUser = getCurrentUser();
    
    if (!!currentUser) {
      setUser(currentUser)
    }
    
    startRefreshTimer();
    
    // eslint-disable-next-line
  }, []); // empty second parameter = trigger only once!!


  return (
    <>
      <MenuBar currentUser={user} />

      <Container fluid className={`mt-0 mx-0 w-100 page${pathClassName}`}>
        <Routes>
          <Route path="/" element=
            {user ? <SearchResults currentUser={user} /> : <Home />}
          />
          <Route path="/cauta/:query" element=
            {user ? <Search currentUser={user} /> : <Home />}
          />
          <Route path="/home" element={<Home />} />

          {!!pages && !!pages.map &&
            pages
              .filter(p => !!p.path)
              .filter(p => p.path !== 'home')
              .map((p) => (
                <Route key={p.path} path={`/${p.path}`} element={<PublicPage page={p} />} />
              ))}


          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/recuperare-parola" element={<LostPass />} />
          <Route path="/resetare-parola/:token" element={<ResetPass />} />
          <Route path="/schimbare-parola/:token/:expire" element={<ChangePasswordForm />} />
          {!!user && <Route path="/profile" element={<UserProfile currentUser={user}/>} />}
          {!!user && <Route path="/profile/adauga-adresa" element={<UserLocationForm />} />}
          {!!user && <Route path="/profile/modifica-adresa/:id" element={<UserLocationForm />} />}

          {!!user && <Route path="/cart" element={<CartPage currentUser={user}/>} />}
          <Route path="/contact" element={<Contact />} />

          {!!user && <Route path="/admin/*" element={<Admin currentUser={user} />} />}


        </Routes>
      </Container>

      <Footer currentUser={user} />
    </>
  );

}

export default App;
