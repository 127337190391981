import PublicBlock from "components/public/PublicBlock";
import PublicMiniBanner from "components/public/PublicMiniBanner";
import PublicMiniCarousel from "components/public/PublicMiniCarousel";

function replaceBlocks(p, index, isContact = false) {
    if (!!p) {
        let foundBlocks = p.match(/~(.*?)~/g);
        if (!!foundBlocks) {
            return (
                <div key={index} className={isContact?"row row-cols-md-2 row-cols-sm-1":"row row-cols-md-4 row-cols-1"}>
                    {foundBlocks.map((val) => {
                        let block = val.replaceAll("~", "");
                        return <PublicBlock key={block} blockName={block} />
                    })}
                </div>
            );
        }
    }

    return null;
}


function replaceMiniCarousel(p, index) {
    if (!!p) {
        let foundCarousels = p.match(/%(.*?)%/g);
        if (!!foundCarousels) {
            return (
                <div key={index} className="row row-cols-md-4 row-cols-1">
                    {foundCarousels.map((val) => {
                        let carouselName = val.replaceAll("%", "");
                        return <PublicMiniCarousel key={carouselName} carouselName={carouselName} />
                    })}
                </div>
            );
        }
    }

    return null;
}


function replaceMiniBanners(p, index) {
    if (!!p) {
        let foundCarousels = p.match(/#(.*?)#/g);
        if (!!foundCarousels) {
            return (
                <div key={index} className="row row-cols-md-4 row-cols-1">
                    {foundCarousels.map((val) => {
                        let carouselName = val.replaceAll("#", "");
                        return <PublicMiniBanner key={carouselName} carouselName={carouselName} />
                    })}
                </div>
            );
        }
    }

    return null;
}


function renderParagraph(p, index, isContact = false) {
    let b = replaceBlocks(p, index, isContact);

    if (!!b) {
        return b;
    }
    
    let c = replaceMiniCarousel(p, index);
    if (!!c) {
        return c;
    }

        
    let m = replaceMiniBanners(p, index);
    if (!!m) {
        return m;
    }
    
    
    return <p key={index}>{p}</p>
}


export { renderParagraph };