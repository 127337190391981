import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BsCartFill, BsCart } from "react-icons/bs";
import { Badge } from "react-bootstrap";


export default function CartIcon() {
    const items = useSelector((store) => store.cart.items);
    const count = items.length;

    useEffect(() => { },
        [items]);

    let plural = count == 1 ? 'produs' : 'produse';
    let toolTip = count > 0 ? `Aveți ${count} ${plural} în comandă` : 'Nu aveți produse în comandă'
    let className = count > 0 ? 'text-warning' : ''

    return (
        /*<OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id="tooltip-cart">
                    {toolTip}
                </Tooltip>
            }
        >*/
        count > 0 ? (
        <div style={{whiteSpace:"nowrap", position:'relative'}}>
            <BsCartFill className={className} />
                <Badge style={{position:'absolute', top:'-5px', left:'10px', fontSize:'8px'}} className="small" pill bg="danger">{count}</Badge>
            </div>
        ) : (<BsCart />)
        /* </OverlayTrigger> */

    )
}
