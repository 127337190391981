import { BsFileEarmarkPdfFill } from "react-icons/bs";

export default function SearchResultsFisaTehnica({ product, nr = 1 }) {
    return (
        !!product.files && product.files.map((f, idx) => (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a key={`${product.id}-${idx}`} href={f.url} target="_blank">
                <BsFileEarmarkPdfFill size="20" color="orange" title={f.name} />
            </a>
        ))
    )
}