import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ApiGet } from "services/ApiService";
import { setError, clearError } from "store/msgStore";

import BootstrapTable from 'react-bootstrap-table-next';


/*
        "id": 34,
        "idMentor": 0,
        "denumire": "Sediu firma",
        "codSediu": 9844,
        "localitate": "TIMISOARA",
        "strada": "",
        "sector": "",
        "numar": "",
        "numar2": "",
        "bloc": "",
        "scara": "",
        "etaj": "",
        "ap": "",
        "judet": "TM",
        "codPostal": "",
        "email": "",
        "telefon": "",
        "tipSediu": "SLF",
        "agent": "",
        "idPartener": 107
        */
export default function AdminParteneriSedii({ partener }) {
    const [data, setData] = useState([]); // need to do a deep copy

    let dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        //dispatch(setLoading(true));
        ApiGet('/parteneri/sedii/' + partener.id, (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();
    }, []);

    const columns = [
        {
            dataField: 'codSediu',
            text: 'Cod',
            headerStyle: { width: '5%' }
        },
        {
            dataField: 'tipSediu',
            text: 'Tip',
            headerStyle: { width: '5%' }
        },
        {
            dataField: 'denumire',
            text: 'Denumire',
        },
        {
            dataField: 'localitate',
            text: 'Localitate',
        },
        {
            dataField: 'strada',
            text: 'Strada',
        },
        {
            dataField: 'numar',
            text: 'Nr',
            headerStyle: { width: '5%' }
        },
        {
            dataField: 'codPostal',
            text: 'Cod Poștal',
        },
        {
            dataField: 'judet',
            text: 'Județ',
            headerStyle: { width: '10%' }
        },
        {
            dataField: 'email',
            text: 'e-Mail',
        },
        {
            dataField: 'telefon',
            text: 'Telefon',
        },
        {
            dataField: 'agent',
            text: 'Agent',
        }
    ]

    //https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
    return (
        <>
            <h4>Sedii</h4>

            {(<BootstrapTable
                hover
                keyField='codSediu'
                data={JSON.parse(JSON.stringify(data))}
                columns={columns}
                condensed
            />
            )}
        </>
    );
}