import Alert from 'react-bootstrap/Alert';

function AlertBox({ message }) {
    return (
        <Alert variant='danger'>
            {message}
        </Alert>
    );
}

export default AlertBox;