import { Form, Container, Row, Col, Card, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ApiPost, ApiGet, ApiDel } from "services/ApiService";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function UserLocationForm() {
    const params = useParams();
    const [edit, setEdit] = useState(false);
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [response, setResponse] = useState(false);
    const [responseError, setResponseError] = useState(false);

    const setData = (data) => {
        if (!!data && !!data[0]) {
            let addr = data[0];
            console.log("Loaded location", addr);
            setName(addr.name);
            setCity(addr.city);
            setCounty(addr.county);
            setPostalCode(addr.postalcode);
            setAddress(addr.address);
            setPhone(addr.phone);
            setEmail(addr.email);
            setEdit(true)
        } else {
            setResponse("Nu am gasit locația!");
            setResponseError(true);
        }
    }

    useEffect(() => {
        if (!!params && !!params.id) {
            ApiGet('/locations/' + params.id,
                (data) => {
                    setData(data)
                },
                (error) => {
                    setResponse(error.response.data);
                    setResponseError(true);
                });
        }
    }, [params]);

    useEffect(() => {
        // render
    }, [response, responseError]);

    const doDelete = (e) => {
        ApiDel('/locations/' + params.id,
        (data) => {
            setResponse("Adresa a fost ștearsă");
            setResponseError(false);
        },
        (error) => {
            setResponse(error.response.data);
            setResponseError(true);
        });
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();

        console.log("Adding new address");

        let payload = {
            name: name,
            address: address,
            city: city,
            postalCode: postalCode,
            county: county,
            phone: phone,
            email: email
        }
        
        if (edit) {
            payload.id = params.id;
        }
        
        ApiPost('/locations', payload,
            (data) => {
                console.log("Received response", data)
                setResponse("Adresa a fost salvată");
                setResponseError(false);
            },
            (error) => {
                setResponse(error.response.data);
                setResponseError(true);
            });
    }

    return (
        <Container>
            <Row className="d-flex justify-content-center">
                <Col md={8} lg={6} xs={12}>
                    <Card>
                        <Card.Header>{edit?'Modifică adresa de livrare':'Adaugă adresă de livrare nouă'}</Card.Header>
                        <Card.Body>
                            {response
                                ? (<>
                                    <div
                                        className={responseError ? "alert alert-danger" : "alert alert-success"}
                                        role="alert">
                                        {response}
                                    </div>
                                    <div className="form-group mt-3 text-center">
                                        <NavLink to="/profile">Înapoi la profil</NavLink>
                                    </div>
                                </>)
                                : (
                                    <Form onSubmit={handleSubmit}>

                                        <Row >
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formName">
                                                    <Form.Label className="text-left mb-3">
                                                        Nume destinatar sau companie
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="nume..."
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)} />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formName">
                                                    <Form.Label className="text-left mb-3">
                                                        Adresa (strada, numar)
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea" rows={3}
                                                        placeholder="strada, numar"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3} xs={12}>
                                                <Form.Group className="mb-3" controlId="formName">
                                                    <Form.Label className="text-left mb-3">
                                                        Cod postal
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="cod..."
                                                        value={postalCode}
                                                        onChange={(e) => setPostalCode(e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} xs={12}>
                                                <Form.Group className="mb-3" controlId="formName">
                                                    <Form.Label className="text-left mb-3">
                                                        Localitate
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="localitate..."
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)} />
                                                </Form.Group>

                                            </Col>
                                            <Col md={3} xs={12}>
                                                <Form.Group className="mb-3" controlId="formName">
                                                    <Form.Label className="text-left mb-3">
                                                        Judet
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="judet..."
                                                        value={county}
                                                        onChange={(e) => setCounty(e.target.value)} />
                                                </Form.Group>

                                            </Col>
                                        </Row>

                                        <Form.Group className="mb-3" controlId="formName">
                                            <Form.Label className="text-left mb-3">
                                                Telefon contact
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="telefon contact"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)} />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formName">
                                            <Form.Label className="text-left mb-3">
                                                Email contact
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="email contact"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)} />
                                        </Form.Group>

                                        <Button className="mt-3" type="submit" variant={edit?'success':'info'}>{edit?'Salvează adresa':'Adaugă adresă'}</Button>
                                        {edit && (
                                            <Button className="mt-3" style={{float:'right'}} onClick={doDelete} variant="danger">Șterge adresa</Button>
                                        )}
                                    </Form>
                                )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}