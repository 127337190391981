import { Carousel, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useRef } from "react";


export default function PublicMiniCarousel({ carouselName }) {
    const miniCarousels = useSelector((store) => store.content.miniCarousels);

    const wrapper = useRef();
    const sign = useRef(1);


    useEffect(() => {
        setInterval(() => {
            if (!!wrapper && !!wrapper.current) {
                let w = wrapper.current.scrollWidth - wrapper.current.clientWidth
                //console.log("scroll", sign.current, wrapper.current.scrollLeft, w)
                if (sign.current > 0) {
                    if (wrapper.current.scrollLeft < w ) {
                        wrapper.current.scrollLeft = wrapper.current.scrollLeft + sign.current * 2;
                    } else {
                        sign.current = -1;
                    }
                } else {
                    if (wrapper.current.scrollLeft > 0) {
                        wrapper.current.scrollLeft = wrapper.current.scrollLeft + sign.current * 2;
                    } else {
                        sign.current = 1;
                    }
                }
            }
        }, 30);
    }, [miniCarousels]);

    if (!miniCarousels) {
        return (<></>)
    }

    if (!miniCarousels[carouselName]) {
        return (<></>)
    }

    return (
        <Container style={{ width: "90%" }} >
            <div className="scrolling-wrapper" ref={wrapper}>
                {miniCarousels[carouselName].map((c) => (
                    <div className="item" key={c.id}>
                        <img
                            height="70px"
                            src={c.image}
                            alt={c.description}
                            style={{ padding: "0px 20px" }}
                        />
                        {c.description}
                    </div>))}
            </div>
        </Container>
    )

}