import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Alert } from "react-bootstrap";


function ErrorMessage() {
    const isError = useSelector((store) => store.msg.error);
    const errorMessage = useSelector((store) => store.msg.errorMessage);

    useEffect(() => { }, [isError, errorMessage]);

    return (
        isError && (errorMessage !== '') ? (
            <Alert className="mt-3" variant='danger'>
                <Alert.Heading>Ne cerem scuze, a apărut o eroare la comunicarea cu serverul</Alert.Heading>
                <strong>{errorMessage}</strong>
                <hr />
                <p className="mb-0">
                    <small>
                        Este posibil ca eroarea să fie temporară, sau să fie legată de conexiunea dumneavoastră la internet.
                        <br />Încercați să reîncărcați pagina. Dacă eroarea persistă, încercați Logout apoi Login.
                        <br />Pentru urgențe vă rugăm să ne căutați direct la telefon.
                    </small>
                </p>
            </Alert>
        ) : ''
    );
}

export default ErrorMessage;