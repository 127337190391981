import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, InputGroup } from "react-bootstrap";
import { BsFillCartCheckFill, BsCartPlus } from 'react-icons/bs';
import { addToCart } from "store/cartStore";


export default function SearchResultsAddToCartBulk({ product, bulk, getClasa, getSimbolClasa, getProducatori, rowColor }) {
    const [quantity, setQuantity] = useState(1);
    const [addedToCart, setAddedToCart] = useState(false);

    const dispatch = useDispatch();

    const onChangeQuantity = (e) => {
        if (!!e.target.value) {
            let q = parseInt(e.target.value);
            console.log("Quantity changed", product.id, q)
            setQuantity(q);
        }
    }

    /* Select all to allow easy add */
    const onFocusQuantity = (event) => {
        const inputNode = event.target;
        inputNode.setSelectionRange(0, inputNode.value.length);
    }

    /* Add to cart button is pressed */
    const onButtonAddToCart = (event) => {
        console.log("Adding to cart box", product.id, quantity, bulk.boxQty)
        setAddedToCart(true);
        dispatch(addToCart(
            {
                product: {
                    ...product,
                    // cache this
                    clasa: getClasa(product.idClasa),
                    simbolClasa: getSimbolClasa(product.idSimbolClasa),
                    producator: getProducatori(product.idProducator)
                },
                quantity: quantity,
                price: bulk.pretBulk,
                boxQty: bulk.boxQty,
                bulkName: bulk.name
            }));
    }

    return (
        <>
            <td className="col-qty align-center">
                <div className="mx-auto" style={{ width: "60px" }}>
                    <InputGroup>
                        <Form.Control width={100}
                            className="qty-input"
                            onChange={onChangeQuantity}
                            onFocus={onFocusQuantity}
                            type="text"
                            defaultValue={quantity}
                            size="sm"
                            disabled={addedToCart}
                        />
                        <Form.Control width={100}
                            className="qty-input"
                            onChange={onChangeQuantity}
                            onFocus={onFocusQuantity}
                            type="text"
                            value={(!!quantity && !!bulk.boxQty) ? (quantity * bulk.boxQty) : 1}
                            size="sm"
                            disabled={true}
                        />
                    </InputGroup>
                </div>
            </td>
            <td className="col-add">
                {addedToCart ? (
                    <BsFillCartCheckFill className="addCartConfirmed" />
                ) : (
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={onButtonAddToCart}
                    >
                        <BsCartPlus />
                    </Button>
                )}
            </td>
        </>
    )
}