import { createSlice } from '@reduxjs/toolkit';

const msgStore = createSlice({
    name: 'msg',
    initialState: {
        tempConfirmation: '',
        loading: false,
        error: false,
        errorMessage: ''
    },
    reducers: {

        setTempConfirmation(state, action) {
            console.log("setTempConfirmation", action.payload);
            state.tempConfirmation = action.payload;
        },

        setLoading(state, action) {
            console.log("setLoading", action.payload);
            state.loading = action.payload;
        },

        setError(state, action) {
            if (!!action.payload) {
                let error = action.payload
                console.log("setError", error);
                state.error = true;

                let message = "";

                if (!!error.message) {
                    message = error.message.toString();
                }

                if (!!error.response.data) {
                    message += ' (' + error.response.data + ')';
                }

                if (!!error.response.status && !!error.response.statusText) {
                    message += ' [' + error.response.status + " " + error.response.statusText + "]";
                }

                if (!!error.code) {
                    message += ': ' + error.code;
                }

                state.errorMessage = message;
            }
        },

        clearError(state, action) {
            state.error = false;
            state.errorMessage = '';
        },


    }
})

export const {
    setLoading, setError, clearError, setTempConfirmation
} = msgStore.actions
export default msgStore.reducer