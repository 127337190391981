import SearchResultsAddToCart from "./SearchResults-AddToCart";
import SearchResultsAddToCartBulk from "./SearchResults-AddToCartBulk";
import SearchResultsFullName from "./SearchResults-FullName";
import SearchResultsFisaTehnica from "./SearchResults-FisaTehnica";
import SearchResultsEchivalente from "./SearchResults-Echivalente";
import SearchResultsStoc from "./SearchResults-Stoc";


export default function SearchResultsProductRowBulk({ product, getClasa, getSimbolClasa, getProducatori, shops, rowClass }) {
    let nr = product.bulk.length + 1;

    return (
        <>
            <tr key={`${product.id}`} className={rowClass}>
                {/* <SearchResultsInfo product={product} nr={nr} /> */}

                <td rowSpan={nr}>{getProducatori(product.idProducator)}</td>
                
                <td rowSpan={nr}><SearchResultsFullName product={product} /></td>

                <SearchResultsEchivalente product={product} nr={nr} />
                
                <td rowSpan={nr} className="text-center"><SearchResultsFisaTehnica product={product} /></td>

                <SearchResultsStoc shops={shops} product={product} nr={nr}/>
                
            
                <td className="text-center" >{product.um}</td>
                <td className="col-pret">{product.pretSite} {product.moneda}</td>
                <SearchResultsAddToCart product={product} getClasa={getClasa} getSimbolClasa={getSimbolClasa} getProducatori={getProducatori} />
            </tr>

            {product.bulk.length > 0 && (product.bulk.map((bulk) => (
                <tr key={`${product.id}-bulk-${bulk.boxQty}`} className={rowClass}>
                    <td className="text-center" style={{ width: '7%', color: 'black'}}><strong>{bulk.name}</strong><br /><small>[{bulk.boxQty} {product.um}]</small></td>
                    <td className="col-pret"> {bulk.pretBulk} </td>
                    <SearchResultsAddToCartBulk product={product} bulk={bulk} getClasa={getClasa} getSimbolClasa={getSimbolClasa} getProducatori={getProducatori}/>
                </tr>
            )))}
            {nr % 2 === 0 && (<tr></tr>)}
        </>
    )
}