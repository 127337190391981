import { Card, Badge } from "react-bootstrap";

import ChangePasswordButton from "components/user/password/ChangePasswordButton";

export default function UserMeta({ currentUser }) {
    return (
        <Card>
            <Card.Header  bg="dark">Informații profil</Card.Header>
            <Card.Body>
                <Card.Title>
                    {currentUser.meta.firstName} {currentUser.meta.lastName} <em>{currentUser.meta.companyName}</em>
                    <small><Badge pill bg="info">#{currentUser.meta.userId}</Badge></small>
                </Card.Title>
                <Card.Text>
                    <strong>Email:</strong>{" "} {currentUser.username}
                </Card.Text>
                {/*<Card.Text>
                <strong>Roles:</strong>
                <ul>
                  {currentUser.roles &&
                    currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
                </ul>
                  </Card.Text> 
              <Card.Text>
                <strong>Token:</strong>{" "}
                {currentUser.accessToken.substring(0, 20)} ...{" "}
                {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
              </Card.Text>
              */}

                <ChangePasswordButton currentUser={currentUser} />
            </Card.Body>
        </Card>
    )
}