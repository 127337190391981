import React from "react"
import SearchLink from "components/common/SearchLink"
import { OverlayTrigger, Popover } from "react-bootstrap";
import { BsRepeat } from "react-icons/bs";


export default function SearchResultsEchivalente({ product, nr = 1 }) {
    if (product.eq.length === 0) {
        return (<td rowSpan={nr}></td>);
    }
    return (
        <>
            <OverlayTrigger
                placement="bottom"
                trigger={['click', 'focus']}
                rootClose
                className='popover-echivalent'
                overlay={
                    <Popover id={`tooltipInfo-${product.id}-${nr}`} style={{ fontSize: '0.6em' }}>
                        <Popover.Header as="h3" style={{ fontSize: '1.2em' }}>Coduri echivalente</Popover.Header>
                        <Popover.Body>
                            <span>{product.codIntern}</span>
                            <ul style={{ margin: 0, padding: 0 }}>
                        
                                    <>
                                        {!!product.codIntern && (<br />)}
                                        {product.eq.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <span><b>{item.brand}</b>: <SearchLink query={item.eqCode} /></span>
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </>
                                
                            </ul>
                        </Popover.Body>
                    </Popover>
                }
            >
                <td className="text-center" rowSpan={nr} style={{ cursor: 'pointer' }}>
                    <BsRepeat style={{ fontSize: '1.5em', fontWeight: 'bold', color: 'orange', strokeWidth: '1px', textAlign: 'center', verticalAlign: 'middle' }} />
                </td>
            </OverlayTrigger>
        </>

    )
}