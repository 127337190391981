
/*
function nl2br_classic (str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}
*/

function nl2br(text) {
    return text.split('\n').map((item, key) => {
        return <span key={key}>{item}<br/></span>
    })
}

export {nl2br}
