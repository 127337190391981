import SearchResults from "./products/SearchResults";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSearchQuery } from "store/productsReducer";

export default function Search({ currentUser }) {
    const params = useParams(); // parameters from URL (react-router)

    const dispatch = useDispatch();
    //const location = useLocation();

    useEffect(() => {
        console.log("Search page params", params);
        dispatch(setSearchQuery(params.query));
    }, [dispatch, params, params.query]);

    return (
        currentUser ? <SearchResults currentUser={currentUser} />
            : ''
    );
}