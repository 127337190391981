import React from "react";
import SearchResultsAddToCart from "./SearchResults-AddToCart";
import SearchResultsFullName from "./SearchResults-FullName";
import SearchResultsFisaTehnica from "./SearchResults-FisaTehnica";
import SearchResultsEchivalente from "./SearchResults-Echivalente";
import SearchResultsStoc from "./SearchResults-Stoc";

export default function SearchResultsProductRow({ product, getClasa, getSimbolClasa, getProducatori, shops, rowClass }) {
    return (
        <React.Fragment key={product.id}>
            <tr className={rowClass}>
                {/*<SearchResultsInfo product={product} />*/}

                <td>{getProducatori(product.idProducator)}</td>
                
                <td><SearchResultsFullName product={product} /></td>
                
                <SearchResultsEchivalente product={product} />
                
                <td className="text-center"><SearchResultsFisaTehnica product={product} /></td>

                <SearchResultsStoc shops={shops} product={product} />
                
               
                <td className="text-center" >{product.um}</td>
                <td className="col-pret">
                {product.promotie==='1' && (<span style={{color:'green', fontSize:'.8em'}}>PROMOTIE<br/></span>)} {product.pretSite}
                    
                </td>
                <SearchResultsAddToCart product={product} getClasa={getClasa} getSimbolClasa={getSimbolClasa} getProducatori={getProducatori} />
            </tr>
        </React.Fragment>
    )
}