import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setError, setTempConfirmation } from "store/msgStore";
import { ApiPut } from "services/ApiService";
import { GrAddCircle } from "react-icons/gr";
import AdminUserAddParteneri from "./AminUser-Add-Parteneri";
import { useNavigate } from "react-router-dom";


export default function AdminUserAdd({ refresh }) {
    const [tip, setTip] = useState('-');
    const [isValid, setValid] = useState(false);
    const [idPartener, setIdPartener] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const doSave = (event) => {
        event.preventDefault();
        const tip = event.target[0].value;
        let payload = { tip: tip };
        console.log("add user: ", tip);

        payload.prenume = event.target[1].value;
        payload.nume = event.target[2].value;
        payload.email = event.target[3].value;

        if (tip === 'P') {
            payload.idPartener = idPartener;
        }

        ApiPut('/users', payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Utilizatorul a fost creat!"))
                refresh();
                navigate("/admin/users");
            },
            (error) => {
                dispatch(setError(error));
            });

        event.target.reset();
        window.scrollTo(0, 0);
    }

    const selectOnChangeTip = (e) => {
        setTip(e.target.value);
        if (e.target.value === 'R') {
            setValid(true);
        } else {
            setValid(idPartener > 0);
        }
    }

    const selectPartner = (row) => {
        console.log("Partener selected", row);
        setIdPartener(row.id);
        setValid(!!row.id)
    }

    return (
        <>
            <h4 className="mt-2"><GrAddCircle className="mb-1" style={{ color: 'orange' }} /> Adauga un nou utilizator</h4>
            <Form onSubmit={doSave}>
                <Form.Group className="mb-3" controlId="formTup">
                    <Form.Label>Tip utilizator</Form.Label>
                    <Form.Select value={tip} aria-label="Tipul de utilizator" onChange={selectOnChangeTip}>
                        <option value="-">alegeti tipul</option>
                        <option value="P">Partener</option>
                        <option value="R">Rolling</option>
                    </Form.Select>
                    <Form.Text className="text-muted">
                        Selectati tipul de utilizator. "Partener" se refera la un client normal, "Rolling" = utilizator din echipa Rolling
                    </Form.Text>
                </Form.Group>


                <Form.Group className="mb-3" controlId="formDescription">
                    <Form.Label>Prenume</Form.Label>
                    <Form.Control type="text" placeholder="prenume" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Nume</Form.Label>
                    <Form.Control type="text" placeholder="nume" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>E-Email</Form.Label>
                    <Form.Control type="email" placeholder="email" />
                </Form.Group>

                {(tip === 'P') && (
                    <AdminUserAddParteneri markSelected={selectPartner} />
                )}

                <Button variant="primary" type="submit" disabled={!isValid}>
                    Adaugă
                </Button>
            </Form>
        </>

    );
}