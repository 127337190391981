import { BsFillTelephoneFill } from "react-icons/bs";
import { MdAlternateEmail } from "react-icons/md";
import { useSelector } from "react-redux";

export default function PublicBlock({ blockName }) {
    const blocks = useSelector((store) => store.content.blocks);

    let block = blocks[blockName];
    
    const isContactBlock = () => {
        return blockName.toString().toLowerCase().startsWith('contact');    
    }
    
    const printBlockContent = (p, index) => {
        if (isContactBlock()) {
            return (<p className="mt-4" key={index} dangerouslySetInnerHTML={{__html: p}}></p>)
        } else {
            return ( <p className="card-text" key={index}>{p}</p>)
        }
    } 
    
    const printWithIcons = (p) => {
        if (p.toLowerCase().startsWith("tel")) {
            return ( <>
                <BsFillTelephoneFill /> {p}
            </>)
        }
        if (p.toLowerCase().startsWith("email") || p.toLowerCase().startsWith("e-mail")) {
            return ( <>
                <MdAlternateEmail /> {p}
            </>)
        }
        return p;
    }
    
    return (
        !!block &&

        <div className="col d-flex align-items-stretch">
            <div className="card" style={isContactBlock?{width:"100%"}:{}}>
                {!!block.image && <img className="card-img-top"
                    src={block.image}
                    style={{ objectFit: "cover", }}
                    alt={block.title}
                />}
                <div className="card-body">
                    {!!block.title && <div className="card-title h5" style={isContactBlock?{color:"#f60"}:{}}>{block.title}</div>}
                    {!!block.sumar && block.sumar.split("\n").map((p) => (<>
                        {printWithIcons(p)}<br/>
                    </>))}
                    {!!block.content && block.content.split("\n").map((p, index) => (
                        printBlockContent(p, index)
                    ))}
                </div>
            </div>
        </div>

    )
}