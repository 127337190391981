
import { Row, Col } from "react-bootstrap";
import CardItem from "components/common/CardItem";
import { adminRoles } from "./Admin";

function AdminPanel({ currentUser }) {
  if (!currentUser) {
    return '';
  }

  let columnsPerRow = 3;

  return (
    <>
      <Row xs={1} md={columnsPerRow}>
        {!!adminRoles && !!adminRoles.map && adminRoles.map((item) => (
          !!item.path && currentUser.roles.includes(item.privilege) && (
            <Col key={item.path}>
              <CardItem
                title={item.title}
                subtitle={item.subtitle}
                text={item.text}
                linkName={item.title}
                linkTo={item.path} />
            </Col>
          )
        ))}
      </Row>
    </>
  );
}

export default AdminPanel;
