import { useRef, useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";


function useInterval(callback, delay) {
    const intervalRef = useRef();
    const callbackRef = useRef(callback);

    // Remember the latest callback:
    //
    // Without this, if you change the callback, when setInterval ticks again, it
    // will still call your old callback.
    //
    // If you add `callback` to useEffect's deps, it will work fine but the
    // interval will be reset.

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // Set up the interval:

    useEffect(() => {
        if (typeof delay === 'number') {
            intervalRef.current = window.setInterval(() => callbackRef.current(), delay);

            // Clear interval if the components is unmounted or the delay changes:
            return () => window.clearInterval(intervalRef.current);
        }
    }, [delay]);

    // Returns a ref to the interval ID in case you want to clear it manually:
    return intervalRef;
}


export default function TimerProgress({ expire }) {
    const [time, setTime] = useState(expire);

   // setTime(expire);   
    const intervalRef = useInterval(() => {
        if (time > 0) {
            setTime(time - 1);
        } else {
            window.clearInterval(intervalRef.current);
        }
    }, 1000);


    return (
        <ProgressBar variant="info" now={time} max={expire} label={`formularul expiră în ${time} secunde`}/>
    )
}