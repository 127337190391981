import { Button, Card, ListGroup } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import { nl2br } from "services/Utils";
import { FiEdit } from "react-icons/fi";

export default function UserLocations({ currentUser }) {
    const [data, setData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/locations', (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        refresh();
        // eslint-disable-next-line  
    }, [])

    const addAddress = () => {
        navigate("/profile/adauga-adresa");
    }
    
    return (
        <Card>
            <Card.Header>Adrese livrare</Card.Header>
      
                <ListGroup variant="flush">
                    {!!data && !!data.map && data.map(i => (
                        <ListGroup.Item key={i.id}>
                            <NavLink to={`/profile/modifica-adresa/${i.id}`} className="text-primary" style={{float:'right'}}><FiEdit /></NavLink>
                            <strong>{i.name}</strong> <br/>
                            <em><small>{nl2br(i.address)}</small></em>
                            <small>{i.postalcode} - {i.city}, {i.county}<br />
                            Tel: {i.phone}<br />
                            Email: {i.email}</small>
                        </ListGroup.Item>
                    ))}                    
                </ListGroup>

             <Card.Body>
                <Button variant="info" size="sm" onClick={addAddress}>Adaugă adresă</Button>
            </Card.Body>
        </Card>
    )
}