import { Row, Col, Container } from "react-bootstrap";

import UserLocations from "./locations/UserLocations";
import UserMeta from "./metainfo/UserMeta";

export default function UserProfile({ currentUser }) {


  return (
    <Container>
      <Row xs={1} md={2} className="g-2">
        <Col>
          <UserLocations currentUser={currentUser} />
        </Col>
        <Col>
          <UserMeta currentUser={currentUser} />
        </Col>
      </Row>

    </Container>
  )
}
