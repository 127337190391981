import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterByClasa } from "store/productsReducer";
import CheckboxList from "components/common/CheckboxList";
import { hashCode } from "common/utils";

function FilterClase() {
    const [options, setOptions] = useState([]);
    const sclase = useSelector((store) => store.products.clase);
    const claseSelected = useSelector((store) => store.products.filters.claseSelected);
    const claseSelectedCounters = useSelector((store) => store.products.counters.claseSelected);

    const dispatch = useDispatch();

    useEffect(() => {
        let opt = [];
        //console.log("FilterClase", claseSelectedCounters, claseSelected)
        if (!!claseSelectedCounters.map) {
            claseSelectedCounters.map((item) => {
                let hash = hashCode(item.display);
                let selected = !!(claseSelected.find((i)=>i === hash));
                opt.push({ name: item.display, count: item.count, id: item.display, selected: selected })
            });
        }
        setOptions(opt.sort((a, b) => (b.count - a.count)));
    },
        [sclase, claseSelected, claseSelectedCounters]);

    useEffect(() => {
        // render
    }, [options]);



    const onSelectProducator = (e) => {
        console.log("onSelect", e.target.id, e.target.checked);
        dispatch(filterByClasa({ display: e.target.id, checked: e.target.checked }));
    }

    return (
        <CheckboxList label='Tip produs' options={options} onSelect={onSelectProducator} />
    );
}

export default FilterClase;
