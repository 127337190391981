
import { Card, ListGroup } from "react-bootstrap"
import { IoMdWarning } from "react-icons/io"

export default function CartFacturare({ currentUser }) {
    return (
        <>
            <h5>Detalii facturare</h5>
            <p className="text-muted">Factura va fi generată folosind datele firmei înregistrate în sistemul Rolling. Pentru modificarea acestora vă rugăm să ne contactați.</p>
            <Card>
                <Card.Header className="selected">
                    <strong>{!!currentUser.meta.denumire
                        ? currentUser.meta.denumire
                        : (<>{currentUser.meta.firstName} {currentUser.meta.lastName} </>)}</strong>
                </Card.Header>

                <ListGroup variant="flush">
                    {
                        (!!currentUser.meta.codFiscal) ? (
                            <>
                                <ListGroup.Item>{!!currentUser.meta.codFiscal && <><strong>CIF</strong>: {currentUser.meta.codFiscal}<br /></>}</ListGroup.Item>
                                <ListGroup.Item>{!!currentUser.meta.registruComert && <><strong>Reg.Com</strong>: {currentUser.meta.registruComert}<br /></>}</ListGroup.Item>
                            </>
                        ) : (
                            <ListGroup.Item><p className="text-danger strong big"><IoMdWarning/> Nu aveți asociat un cod fiscal în sistemul Rolling!<br/>Vă rugăm să ne contactați pentru a vă înregistra în sistem!</p></ListGroup.Item>
                        )
                    }
                    <ListGroup.Item><strong>Reprezentant</strong>: {currentUser.meta.firstName} {currentUser.meta.lastName}</ListGroup.Item>
                </ListGroup>
            </Card>
        </>
    )
}