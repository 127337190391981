import { useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { BsXCircle } from "react-icons/bs";

import { updateQuantity } from "store/cartStore";


function CartPageDel({ item, quantity }) {

    let dispatch = useDispatch();

    const onClickDelete = (e) => {
        dispatch(updateQuantity({ productId: item.product.id, boxQty: item.boxQty, quantity: 0 }));
        console.log("Delete product", item.product.id)
    }

    return (
        <Button
            onClick={onClickDelete}
            variant="outline-danger"
            size="sm"
        >
            <BsXCircle />
        </Button>
    )

}

export default CartPageDel