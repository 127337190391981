import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

export default function PasswordInputWithValidation( {setNewPassword, setAllOk} ) {
    const [message, setMessage] = useState([]);
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    
    const onChangePassword1 = (e) => {
        setPassword1(e.target.value);
    }

    const onChangePassword2 = (e) => {
        setPassword2(e.target.value);
    }

    const validate = () => {
        let msg = [];

        // check length
        if (!!password1) {
            if (password1.length < 8) {
                msg.push("Parola trebuie să aibă minim 8 caractere");
            }
            if (!(password1.match(/[a-z]/) && password1.match(/[A-Z]/))) {
                msg.push("Folosiți litere mari și litere mici");
            }
            if (!password1.match(/\d/)) {
                msg.push("Folosiți cel puțin o cifră");
            }
            if (!password1.match(/[^a-zA-Z\d]/)) {
                msg.push("Folosiți cel un caracter special");
            }
        }

        // password not complex yet
        if (msg.length) {
            setMessage(msg);
            // !!! uncomment below to enforce password complexity !!
            //return; 
        }

        // complexity ok, check match
        if (!!password1 && (!!password2) && (password1 !== password2)) {
            msg.push("Parolele nu corespund");
        }

        setMessage(msg);

        let ok = !!password1 && (!!password2) && (password1 === password2);
        
        setAllOk(ok);
        
        if (ok) {
            setNewPassword(password1);
        }
    };


    useEffect(() => {
        validate();

        // eslint-disable-next-line 
    }, [password1, password2]);

    useEffect(() => {
        // refresh
    }, [message]);

    
    return (
        <>

            <Form.Group className="form-group mb-3">
                <Form.Label htmlFor="password1" className="text-left mb-3">
                    Introduceți noua parolă
                </Form.Label>
                <Form.Control
                    autoComplete="off"
                    type="password"
                    className="form-control"
                    name="password1"
                    value={password1}
                    onChange={onChangePassword1}
                />
            </Form.Group>
            <Form.Group className="form-group mb-3">
                <Form.Label htmlFor="password2" className="text-left mb-3">
                    Verificați noua parolă
                </Form.Label>
                <Form.Control
                    autoComplete="off"
                    type="password"
                    className="form-control"
                    name="password2"
                    value={password2}
                    onChange={onChangePassword2}
                />
            </Form.Group>
            {!!message && (message.length > 0) && (
                <div className="form-group mt-1">
                    <div className="alert alert-info small" role="alert">
                        <ul className="pb-0 mb-0">
                            {message.map((i, index) => (
                                <li key={index}>{i}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

        </>
    )
}