import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTempConfirmation, setError } from "store/msgStore";
import { ApiGet, ApiPut } from "services/ApiService";
import { Form } from "react-bootstrap";

export default function AdminUserRoles({ userData, refresh }) {

    const [roles, setRoles] = useState([]);
    const [noRights, setNoRights] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        ApiGet('/roles', (data) => { setRoles(data) }, (error) => { setNoRights(true) });
    }, []);

    const confirm = (data) => {
        dispatch(setTempConfirmation("Rolul a fost modificat"))
        refresh();
    }

    const onChangeRole = (e) => {
        console.log("User role changed", e);
        if (!e || !e.target) {
            return
        }
        let checked = e.target.checked;
        let roleId = e.target.value;

        let payload = { userId: userData.id, roleId: roleId, checked: checked }
        ApiPut('/user/' + userData.id, payload, (data) => { confirm(data) }, (error) => { dispatch(setError(error)); });
    }

    if (noRights) {
        return <></>
    }

    return (
        <>
            <Form>
                {!!roles && !!userData && roles.map(genericRole => (
                    <Form.Check
                        key={`${userData.id}-${genericRole.id}`}
                        type="switch"
                        id={`role-${genericRole.id}`}
                        disabled={genericRole.name === 'roles.admin'}
                        label={genericRole.name}
                        checked={!!userData && !!userData.roles && userData.roles.includes(genericRole.name)}
                        value={genericRole.id}
                        onChange={onChangeRole}
                    />

                ))}
            </Form>
        </>
    )
}