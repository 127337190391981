
export default function AdminUserAuditAccess({ user }) {
    console.log(user);
    return (
        <>
            <ul>
                <li>Ultimul login reușit: <strong>{user.lastSuccesfullLogin}</strong></li>
                <li>Erori login: <strong>{user.failedAttempts}</strong></li>
                <li>Ultimul login eșuat: <strong>{user.lastFailedAttempt}</strong></li>
                <li>Blocare login: <strong>{user.lockTime}</strong></li>
            </ul>
        </>
    )
}