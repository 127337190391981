import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import AdminUserTypesEditClaseAccess from "./AdminUserType-Edit-ClaseAccess";
import AdminUserTypesEditClaseDiscount from "./AdminUserType-Edit-ClaseDiscount";


export default function AdminUserTypesClaseEdit() {
    const params = useParams(); // parameters from URL (react-router)
    const [userType, setUserType] = useState([]);

    const dispatch = useDispatch();
    //const [currentUserId, setCurrentUserId] = useState(0);


    const refresh = () => {
        dispatch(clearError());
        ApiGet('/user-type-clase/' + params.id, (data) => { setUserType(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        refresh();
    }, []);


    /* future for drag/drop: https://rct.lukasbach.com/docs/guides/uncontrolled-environment */
    /* https://github.com/jakezatecky/react-checkbox-tree */
    return (
        <>
            <h2>{userType.name} - drepturi de acces</h2>
            <p>{userType.description}</p>

            <Tabs
                defaultActiveKey="accessClase"
                id="userTypeTabs"
                className="mb-3"
            >
                <Tab eventKey="accessClase" title="Access clase">
                    <AdminUserTypesEditClaseAccess userType={userType} refresh={refresh} />
                </Tab>
                <Tab eventKey="discountClase" title="Discount clase">
                    <AdminUserTypesEditClaseDiscount userType={userType} refresh={refresh} />
                </Tab>
            </Tabs>

        </>

    );
}