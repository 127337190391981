import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { loadClase, loadProducatori, loadShops, searchResults } from "store/productsReducer";
import { setLoading, setError } from "store/msgStore";
import { ApiGet } from "services/ApiService";

import { useDispatch } from "react-redux";

import SearchBarDirect from "./search/SearchBar-Direct";

function SearchBar({ user }) {

    let location = useLocation();
    let isHome = location.pathname === '/';
    let isCauta = location.pathname.startsWith('/cauta/');

    let dispatch = useDispatch();


    const loadError = (error) => {
        console.log("searchError", error);
        dispatch(setError(error));
        dispatch(setLoading(false));
        dispatch(searchResults([]));
    }


    useEffect(() => {
        console.log("New user, refreshing meta", user);
        ApiGet('/catalog/clase', (data) => { dispatch(loadClase(data)) }, loadError);
        ApiGet('/catalog/producatori', (data) => { dispatch(loadProducatori(data)) }, loadError);
        ApiGet('/shops', (data) => { dispatch(loadShops(data)) }, loadError);
    }, [user]);



    return (
        <SearchBarDirect />
    );

}

export default SearchBar;
