import { Form, Button } from "react-bootstrap";
import { ApiGet, ApiPost } from "services/ApiService";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError } from "store/msgStore";

export default function AdminMentorRest() {
    var [data, setData] = useState();
    var [command, setCommand] = useState();
    var [mentor, setMentor] = useState();
    var dispatch = useDispatch();

    useEffect(() => { }, [data, mentor]);

    useEffect(() => {
        ApiGet('/admin-mentor', (data) => {
            setMentor(data)
        },
            (error) => { dispatch(setError(error)); });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        console.log("Send WinMentor REST", command);

        let payload = { "command": command, "method": "GET" }
        ApiPost("/admin-mentor",
            payload,
            (data) => setData(data),
            (error) => { dispatch(setError(error)); });
    }

    const onChangeCommand = (e) => {
        setCommand(e.target.value)
    }


    return (
        <>
            <h1>WinMentor - REST Server</h1>
             <Form>
                <Form.Group className="mb-3" controlId="formDescription">
                    <Form.Label>Comanda REST-Server</Form.Label>
                    <Form.Control type="text" placeholder="command" onChange={onChangeCommand} />
                    <Form.Text className="text-muted">
                        Comanda WinMentor rest server
                    </Form.Text>
                </Form.Group>

                <Button variant="primary" type="submit" onClick={onSubmit}>GET</Button>

            </Form>

            <p className="mt-3 p-1" style={{ border: '1px solid orange', backgroundColor: '#323232', color: 'orange', fontSize: '.8em' }}>
                <pre>
                    {JSON.stringify(data, null, 2)}
                </pre>
            </p>

            <p>
                Exemple:
                <ul>
                    <li>/GetInfoArticol/&lt;<em>CodExtern</em>&gt;</li>
                    <li>/GetListaGestiuni</li>
                    <li>/GetInfoPartener/RestServer/RO18341159</li>
                    <li>/GetListaGestiuni</li>
                </ul>
            </p>
            
            <p>Adresa curenta WinMentor REST-Server:
                <pre className="text-success p-1" style={{ border: '1px solid orange', backgroundColor: '#f3f3f3', color: 'blue', fontSize: '.7em' }}>{mentor}</pre></p>
       
        </>
    )
}