import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ApiPost } from "services/ApiService";
import { NavLink } from "react-router-dom";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import PasswordInputWithValidation from "./PasswordInputWithValidation";

export default function ResetPass() {
    const params = useParams(); // params from query
    const [allOk, setAllOk] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [response, setResponse] = useState(false);
    const [responseError, setResponseError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        ApiPost(
            "/resetpass",
            { token: params.token, pass: newPassword },
            (data) => {
                setResponse(data);
                setResponseError(false);
            },
            (error) => {    
                setResponse(error.response.data);
                setResponseError(true);
            });
    }
    
    useEffect(() => {
        // refresh
    }, [allOk]);

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={6} lg={4} xs={12}>
                    <Card className="shadow mt-5">
                        <Card.Body>
                            <div className="mb-3 mt-md-4">
                                <h5 className="mb-4">Resetează parola</h5>
                                {response
                                    ? (<>
                                        <div
                                            className={responseError ? "alert alert-danger" : "alert alert-success"}
                                            role="alert">
                                            {response}
                                        </div>
                                        <div className="form-group mt-3 text-center">
                                            <NavLink to="/login">Login B2B</NavLink>
                                        </div>
                                    </>)
                                    : (
                                        <div className="mb-3">
                                            <Form onSubmit={handleSubmit}>
                                                 <PasswordInputWithValidation setNewPassword={setNewPassword} setAllOk={setAllOk}/>
                                            
                                                <div className="d-grid mt-4">
                                                    <Button variant="warning" type="submit" disabled={!allOk}>
                                                        Schimbă parola
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    )}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}