
function Contact() {

    return (
        <>
            <h1>Contact</h1>
            <p>...</p>
        </>
    )
}

export default Contact;